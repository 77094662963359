import { Typeahead } from "react-bootstrap-typeahead";
import GradeUnit from "../../models/gradeUnit";
import { useEffect, useState } from "react";
import Project from "../../models/project";
import GradeTypes from "../../models/gradeTypes";
import { api } from "../../store/api";

type SelectGradeUnitProps = {
  project: Project;
  grade: GradeTypes;
  selected?: GradeUnit;
  onSelect?: (selected: GradeUnit | undefined) => void;
}
export default function SelectGradeUnit(props: SelectGradeUnitProps) {

  // const [isLoading, setIsLoading] = useState(false);
  const [grade] = useState<GradeTypes>(props.grade);
  const [options, setOptions] = useState<GradeUnit[]>([]);
  const [selected, setSelected] = useState<GradeUnit | undefined>(props.selected);
  //const [valueOptions, setValueOptions] = useState<GradeUnit[]>();

  const createGradeUnit = (request: GradeUnit) => {
    // is loading?
    api.project(props.project.id!).grade(props.grade).unit(request.unit).create().then((gradeUnit) => {
      console.warn('setting selected', gradeUnit);
      setSelected(gradeUnit);
      setOptions([gradeUnit, ...options]);
    });
    // TODO handle error
  };

  // Load typeahead-choice options for value selector
  useEffect(() => {
    console.warn('grade change?', props.grade, selected);
    // load GradeUnits for Project
    if (grade !== props.grade) {
      setSelected(undefined);
    }
    setOptions([]);
    if (props.project.id) {
      api.project(props.project.id).grade(props.grade).units().then(setOptions);
    }
  }, [props.project, props.grade, grade]);

  useEffect(() => {
    console.log('selected changed', selected);
    if (props.onSelect) {
      props.onSelect(selected);
    }
  }, [props, selected]);

  return <div className="d-flex flex-row align-items-center">
    <Typeahead
      id="selectGradeUnit"
      //id={`choices.${props.index}.dependantOnAttributeChoiceIds`}
      options={options}
      labelKey="unit"
      allowNew={true}
      newSelectionPrefix="Create new unit: "
      clearButton={true}
      emptyLabel={'Select...'}
      multiple={false}
      selected={selected !== undefined ? [selected] : []}
      isLoading={false}
      onChange={(selected) => {
        const selectedGradeUnits = selected as GradeUnit[];
        // console.log('onChange', selectedGradeUnits);
        if (selectedGradeUnits.length > 0) {
          const asCustomOption = selectedGradeUnits[selectedGradeUnits.length - 1] as GradeUnit;
          if (asCustomOption.customOption) {
            createGradeUnit(asCustomOption);
          }
          else {
            setSelected(asCustomOption);
          }
        }
        else {
          setSelected(undefined);
        }
      }}
    />    
  </div>;
}