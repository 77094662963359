// Create our number formatter.
const numberFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
});
const percentageFormatter = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 2,
  style: 'percent',
});
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const format = {
  currency: (amount: number | bigint) => currencyFormatter.format(amount),
  number: (value: number | bigint) => numberFormatter.format(value),
  percentage: (value: number | bigint) => percentageFormatter.format(value),
  ago(value: Date): string {
    if (value instanceof Date) {
      var seconds = Math.floor((new Date().getTime() - value.getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      const days = Math.floor(interval)
      return days + " " + (days !== 1 ? 'days' : 'day') +" ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    if (seconds > 10) {
      return Math.floor(seconds) + " seconds ago";
    }
    return "just now";
  }
  return "";
  },
};