import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { Panel, PanelBody } from "../../components/panel/panel";
import Attribute from "../../models/attribute";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import Project from "../../models/project";
import { PartnerDropdown } from "../../components/partner/partnerDropdown";
import Reviewer from "../../models/reviewer";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import ProjectReviewer from "../../models/projectReviewer";
import GradeTypes from "../../models/gradeTypes";

export const ProjectReviewersEditRoute = "/projects/:projectId/reviewers/:id/edit";
export const ProjectReviewersCreateRoute = "/projects/:projectId/reviewers/create";

interface ProjectReviewerEditScreenParams {
  id?: number;
  projectId?: number;
  grade?: GradeTypes;
}

export default function ProjectReviewerEditScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = labels.reviewer;
  const entityApi = api.reviewers;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<ProjectReviewer>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof ProjectReviewerEditScreenParams>();

  const fetchRecord = async (): Promise<ProjectReviewer> => new Promise<ProjectReviewer>((resolve) => {
    if (params.id) {
      setIsLoading(true);
      entityApi.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          resolve(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          setIsLoading(false);
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      const projectId = params.projectId;
      const gradeParam = params.grade;
      let grade: GradeTypes | undefined = undefined;
      if (gradeParam) {
        grade = parseInt(gradeParam);
      }
      if (projectId) {
        api.projects.get(parseInt(projectId)).then((project) => {
            resolve({
              project: project,
              projectId: project?.id,
              grade: grade,
            });
          });
      }
      else {
        resolve({
          grade: grade,
        });
      }
    }
  });

  const form = useForm<ProjectReviewer>({
    defaultValues: fetchRecord,
  });

  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<ProjectReviewer> = data => {
    console.log(data);
    const request: ProjectReviewer = {
      ...data,
      projectId: data.project?.id ?? data.projectId,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        navigate(generatePath(ProjectDetailsRoute, { id: `${record.projectId}` }));
        setIsLoading(false);
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };


  // Bind Suite values to the attribute inputs
  useEffect(() => {
    reset(defaultValue);
  }, [replace, defaultValue, reset]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  const watchProject = useWatch({
    control: control,
    name: `project`
  });


  return (<div>
    <ol className="breadcrumb float-xl-end">
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      {isEditing && <li className="breadcrumb-item active">Edit</li>}
      {!isEditing && <li className="breadcrumb-item active">Create</li>}
    </ol>
    <h1 className="page-header">{label.singular} Details</h1>
    <div className="row">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel className="card border-0" isLoading={isLoading}>
            <PanelBody className="card-body">
              {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Name</label>
                <div className="col-md-9">
                  <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} />
                  {errors.name && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Project</label>
                <div className="col-md-9">
                  {watchProject?.partner?.name} / {watchProject?.name}
                  {errors.name && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>

              <div className="row mb-0">
                <div className="offset-md-3 col-md-9 pt-2">
                  <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                  <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </form>
      </FormProvider>
    </div>
  </div>);
}
