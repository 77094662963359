import { useCallback, useState } from "react";
import Tag, { tagHasDescendantwithId, tagOrAncestorIsUserManaged } from "../../models/tag";
import { useAppSelector } from "../../store/hooks";
import { selectTags } from "../../store/configSlice";
import { faShowerAlt } from "@fortawesome/pro-solid-svg-icons";

type TagTreeProps = {
  selectedTagIds: number[];
};

type TagTreeItemProps = {
  selectedTagIds: number[];
  tags: Tag[];
  tag: Tag;
  showAllSubTags: boolean;
};

// function getHighlightedText(text: string, highlight: string) {
//   // Split text on highlight term, include term itself into parts, ignore case
//   const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
//   return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <u className="">{part}</u> : part)}</span>;
// }
export function TagTree(props: TagTreeProps) {
  const tags = useAppSelector(selectTags);
  const { selectedTagIds } = props;
  const subTags = tags?.filter(t => t.parentTagId === null);

  return <>
    <div className="mt-2">
      {tags && subTags?.map((subTag) => <TagTreeItem key={`subtag_${subTag.id}`} tags={tags} selectedTagIds={selectedTagIds} tag={subTag} showAllSubTags={false} />)}  
    </div>
  </>;
}

function TagTreeItem(props: TagTreeItemProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { tags, tag, selectedTagIds, showAllSubTags } = props;
  const subTags = tags?.filter(t => t.parentTagId === tag.id);

 
  
  const canAddSubtag = tagOrAncestorIsUserManaged(tags, tag);

  const isSelected = selectedTagIds.includes(tag.id ?? -1);
  //const childIsSelected = isChildSelected(tag);
  const canExpand = ((subTags?.length ?? 0) > 0) || canAddSubtag;
  const isChildSelected = tagHasDescendantwithId(tags, tag, selectedTagIds);

  if (!showAllSubTags && !isSelected && !isChildSelected) {
    // do not show
    return <></>;
  }

  return <>
    <div className="mt-2">
      <button type="button" className="btn btn-sm  px-1 py-0" onClick={canExpand ? (e) => setIsExpanded(cur => !cur) : undefined}>
        {!isExpanded && (canExpand ? <i className="fa-solid fa-fw fa-caret-right"></i> : <i className="fa-solid fa-fw"></i>)}
        {isExpanded && <i className="fa-solid fa-fw fa-caret-down"></i>}
        {tag.label}
      </button>
      {(tag.description?.length ?? 0) > 1 && <i className="fa-solid fa-circle-info" title={tag.description}></i>}
      {/* {canAdd && <button onClick={(e) => props.onAdd(tag)} type="button" className="btn btn-sm btn-outline-success ms-2 px-1 py-0" title={"Tag w/ " + tag.label}>Tag</button>} */}
      {!isSelected && isChildSelected && <div className="btn btn-sm btn-gray-500 ms-2 px-1 py-0" title={tag.label + " is included via a selection below"}>incl.</div>}
      {isSelected && <div className="btn btn-sm btn-outline-warning ms-2 px-1 py-0" title={"Selected " + tag.label}>Selected</div>}
      {/* {canAddSubtag && <span className="ms-4"><button className="btn btn-sm btn-outline-secondary px-1 py-0"><i className="fas fa-plus"></i> Create Sub-Tag</button></span>} */}
    </div>
    <div className="ms-4">
      {isExpanded && subTags?.map((subTag) => <TagTreeItem key={`subtag_${subTag.id}`} tags={tags} selectedTagIds={props.selectedTagIds} tag={subTag} showAllSubTags={showAllSubTags || isSelected} />)}
    </div>
  </>;
}