import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { Panel, PanelBody } from "../../components/panel/panel";
import Attribute from "../../models/attribute";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import User from "../../models/user";
import { UsersRoute } from "./usersScreen";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import Roles from "../../models/roles";

export const UsersCreateRoute = '/settings/users/create';
export const UsersEditRoute = "/settings/users/:id/edit";

interface UserEditScreenParams {
  id?: string;
}

export default function UserEditScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const labels = useAppSelector(selectLabels);
  const label = labels.user;
  const entityApi = api.users;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<User>();
  const [error, setError] = useState<String>();

  const params = useParams<keyof UserEditScreenParams>();

  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.User];
  useEffect(() => {
    dispatch(fetchAttributes(EntityTypes.User));
  }, [dispatch]);

  const form = useForm<User>();

  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<User> = data => {
    console.log(data);
    const request: User = {
      ...data,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        if (location.state?.returnUrl) {
          navigate(location.state.returnUrl);
        }
        else {
          navigate(generatePath(UsersRoute, { id: `${record.id}` }));
        }
        setIsLoading(false);
        setError(undefined);
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };

  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      entityApi.get(params.id!)
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      setDefaultValue(new User());
    }
  }, [dispatch, entityApi, label.singular, params.id]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });
    }
  }, [attributes, replace, defaultValue, reset]);


  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });


  return (<div>
    <ol className="breadcrumb float-xl-end">
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      {isEditing && <li className="breadcrumb-item active">Edit</li>}
      {!isEditing && <li className="breadcrumb-item active">Create</li>}
    </ol>
    <h1 className="page-header">{label.singular} Details</h1>
    <div className="row">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Panel className="card border-0" isLoading={isLoading}>
            <PanelBody className="card-body">
              {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">First Name</label>
                <div className="col-md-9">
                  <input type="text" className={"form-control mb-5px " + (errors.firstName ? 'is-invalid' : '')} {...register("firstName", { required: true })} />
                  {errors.firstName && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Last Name</label>
                <div className="col-md-9">
                  <input type="text" className={"form-control mb-5px " + (errors.lastName ? 'is-invalid' : '')} {...register("lastName", { required: true })} />
                  {errors.lastName && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Email</label>
                <div className="col-md-9">
                  <input type="text" className={"form-control mb-5px " + (errors.email ? 'is-invalid' : '')} {...register("email", { required: true })} />
                  {errors.email && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Timezone</label>
                <div className="col-md-9">
                  <select className={"form-control mb-5px " + (errors.timeZone ? 'is-invalid' : '')} {...register("timeZone", { required: true })}>
                    <option></option>
                    <option value="Hawaiian Standard Time">(UTC-10:00) Hawaii</option>
                    <option value="Alaskan Standard Time">(UTC-09:00) Alaska</option>
                    <option value="Pacific Standard Time">(UTC-08:00) Pacific Time (US and Canada)</option>
                    <option value="Pacific Standard Time (Mexico)">(UTC-08:00) Baja California</option>
                    <option value="Mountain Standard Time">(UTC-07:00) Mountain Time (US and Canada)</option>
                    <option value="US Mountain Standard Time">(UTC-07:00) Arizona</option>
                    <option value="Central Standard Time">(UTC-06:00) Central Time (US and Canada)</option>
                    <option value="Eastern Standard Time">(UTC-05:00) Eastern Time (US and Canada)</option>
                    <option value="US Eastern Standard Time">(UTC-05:00) Indiana (East)</option>
                  </select>
                  {errors.timeZone && <div className="invalid-feedback">This field is required</div>}
                </div>
              </div>

              {fields?.map((field, index) => {
                const attribute = attributes?.find(attrib => attrib.id === field.id);
                if (attribute) {
                  console.log('field', attribute.name, field);
                  return (<div key={field.id} className="row mb-15px">
                    <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                    <div className="col-md-9">
                      <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                    </div>
                  </div>
                  );
                }
                return <div key={field.id}></div>;
              }
              )}

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Role(s)</label>
                <div className="col-md-9 pt-2">
                  {Object.keys(Roles).map((role) =>
                    <div key={role} className="form-check mb-2 form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={role}
                          {...register("roles")}
                        // checked={choicesField.value?.includes(choice.id!) ?? false}
                        // onChange={(e) => {
                        //   const valueCopy = e.target.checked
                        //     ? [...(choicesField.value || []), choice.id!]
                        //     : choicesField.value?.filter((item: number) => item !== choice.id) || [];
                        //   // send data to react hook form
                        //   // setSelectedChoices(valueCopy);
                        //   choicesField.onChange(valueCopy);
                        // }}
                        />
                        {role}
                      </label>
                    </div>
                  )}
                </div>


              </div>

              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">Status</label>
                <div className="col-md-9 pt-2">
                  <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                    <label className="form-check-label" htmlFor="isActive">Active</label>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="offset-md-3 col-md-9 pt-2">
                  <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                  <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </form>
      </FormProvider>
    </div>
  </div>);
}
