import AttributeValue from "./attributeValue";
import EntityTypes from "./entityTypes";

export default interface AttributeCondition {
	relatedEntity?: EntityTypes;
	attributeId?: number;
	value?: AttributeValue;
}

// export type ConditionAttributeValues = {
// 	[attributeId: number]: AttributeValue;
// };
export const conditionMatch = (condition: AttributeCondition, attributeValues: AttributeValue[]): boolean => {
	// console.log('conditionMatch', condition, attributeValues);
	const attributeValue = attributeValues.find((av) => av.id === condition.attributeId);
	if (attributeValue) {
		if (condition.value?.choicesValue) {
			// console.warn('conditionMatch choicesValue', condition.value.choicesValue, attributeValue.choicesValue);
			return condition.value?.choicesValue.some((choice) => attributeValue.choicesValue?.includes(choice) ?? false);
			//return attributeValue.choicesValue?.some((choiceValue) => condition.value!.choicesValue!.includes(choiceValue)) ?? false;
		}
	}
	return false;
};