import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Partner from "../../models/partner";
import Project from "../../models/project";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useInsertionEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import ProjectGrade from "../../models/projectGrade";
import GradeUnit from "../../models/gradeUnit";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import ProjectResource from "../../models/projectResource";
import { format } from "../../helpers/format";
import * as Highcharts from 'highcharts';
import highchartsTreegraph from "highcharts/modules/treegraph";
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";
import highchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import { ReportUnit, ReportUnitResource } from "../../models/reportUnit";
import { useSearchParams } from "react-router-dom";
import { setTitle } from "../../util/useDocumentTitle";

export const ResourceQualityReportRoute = "/reports/resourceQuality";

export default function ResourceQualityReportScreen() {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');
  // const location = useLocation();

  // highchartsHeatmap(Highcharts);
  // highchartsTreemap(Highcharts);
  // highchartsTreegraph(Highcharts);
  highchartsExporting(Highcharts);
  highchartsMore(Highcharts);

  const [isLoading, setIsLoading] = useState(false);
  const [partner, setPartner] = useState<Partner>();
  const [partners, setPartners] = useState<Partner[] | undefined>([]);
  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [grade, setGrade] = useState<ProjectGrade>();
  const [grades, setGrades] = useState<ProjectGrade[]>([]);
  const [units, setUnits] = useState<GradeUnit[]>([]);
  const [unit, setUnit] = useState<GradeUnit>();
  
  const [projectResources, setProjectResources] = useState<ProjectResource[]>();

  const [error, setError] = useState<String>();
 
  // const [projectGrades, setProjectGrades] = useState<ProjectGrade[] | undefined>(undefined);
  // const [showAddReviewer, setShowAddReviewer] = useState(false);
  // const [sortReviewers, setSortReviewers] = useState<RecordFilter<ProjectReviewer>>();

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setPartner(project.partner);
        setProject(project);
      });
    }
  }, [projectIdStr]);

  useEffect(() => {
    if (partner) {
      api.projects.forPartner(partner.id!).then(setProjects);
    }
    else {
      setPartners([]);
      setProject(undefined);
    }
  }, [partner]);

  useEffect(() => {
    if (project) {
      api.project(project.id!).grades().then(setGrades);
    }
    else {
      setGrades([]);
    }
  }, [project]);

  useEffect(() => {
    console.log("fetching units", project, grade);
    if (project?.id && grade?.grade) {
      api.project(project.id!).grade(grade!.grade).units().then(setUnits);
    }
    else {
      setUnits([]);
    }
  }, [grade, project]);

  useEffect(() => {
    if (project?.id && unit?.unit && grade?.grade) {
      setIsLoading(true);
      api.project(project.id).grade(grade!.grade).unit(unit.unit).resources().then((resources) => {
        setProjectResources(resources);
        setIsLoading(false);
      });
    }
    else {
      setProjectResources(undefined);
    }
  }, [grade, project?.id, unit]);

  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      grade ? `Grade ${labels.grades[grade.grade]}` : '',
      unit ? `Unit ${unit.unit}` : '',
      `Resource Quality Report`,
    ]);
  }, [grade, labels.grades, project, unit]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Resource Quality Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          <li className="breadcrumb-item active">Resource Quality Report</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            Report Parameters
          </div>
          <div className="card-body">
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3"><EntityTypeLabel entityType={EntityTypes.Partner} singlular /></label>
              <div className="col-md-9">
                <AsyncTypeahead
                  options={partners ?? []}
                  id="partnerDropDown"
                  labelKey={"name"}
                  filterBy={['name']}
                  selected={partner ? [partner] : undefined}
                  minLength={0}
                  isLoading={partners === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedPartners = selected as Partner[];
                    if (selectedPartners.length > 0) {
                      setPartner(selectedPartners[0]);
                    }
                    else {
                      setPartner(undefined);
                    }
                  }}
                  onSearch={function (search: string): void {
                    setPartners(undefined);
                    api.partners.search({ search }).then((partners) => {
                      setPartners(partners.records);
                    })
                      .catch((reason) => {
                        throw new Error("Error searching for " + labels.partner.plural + ": " + reason);
                      });
                  }}
                />
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
              <div className="col-md-9">
                {partner ? <Typeahead
                  options={projects ?? []}
                  disabled={partner === undefined}
                  id="projectDropDown"
                  labelKey={"name"}
                  filterBy={['name']}
                  selected={project ? [project] : undefined}
                  minLength={0}
                  isLoading={projects === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedProjects = selected as Project[];
                    if (selectedProjects.length > 0) {
                      setProject(selectedProjects[0]);
                    }
                    else {
                      setProject(undefined);
                    }
                  }}
                /> : <input disabled className="form-control" />}
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3">Grade</label>
              <div className="col-md-9">
                {(grades?.length ?? 0) > 0 ? <Typeahead
                  options={grades}
                  id="gradeDropDown"
                  // labelKey={"name"}
                  labelKey={(option) => {
                    const projectGrade = option as ProjectGrade;
                    return labels.grades[projectGrade.grade];
                  }}
                  filterBy={['name']}
                  selected={grade ? [grade] : undefined}
                  minLength={0}
                  isLoading={grades === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange grade', selected);
                    const selectedGrades = selected as ProjectGrade[];
                    if (selectedGrades.length > 0) {
                      setGrade(selectedGrades[0]);
                    }
                    else {
                      setGrade(undefined);
                    }
                  }}
                /> : <input className="form-control" disabled />}
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-md-3">Unit</label>
              <div className="col-md-9">
                {(units?.length ?? 0) > 0 ? <Typeahead
                  options={units ?? []}
                  id="unitDropDown"
                  labelKey={'unit'}
                  filterBy={['unit']}
                  selected={unit ? [unit] : []}
                  minLength={0}
                  isLoading={units === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedUnits = selected as GradeUnit[];
                    if (selectedUnits.length > 0) {
                      setUnit(selectedUnits[0]);
                    }
                    else {
                      setUnit(undefined);
                    }
                  }}
                /> : <input className="form-control" disabled />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        {project && unit && projectResources && <OverallQualityReport key={unit.id} project={project} unit={unit} projectResources={projectResources} />}
        
        {/* {project && units && units.map(unit => <UnitReport key={unit.id} project={project} unit={unit} />)} */}
      </div>
    </div>
  </div>);
}

const colorForScore = (score: number) => {
  if (score >= 90) {
    return 'bg-gradient-success';
  }
  if (score >= 60) {
    return 'bg-gradient-cyan-blue';
  }
  return 'bg-gradient-yellow-red';
};

type OverallQualityReportProps = {
  project: Project;
  unit: GradeUnit;
  projectResources: ProjectResource[];
}
function OverallQualityReport(props: OverallQualityReportProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [score, setScore] = useState<number>();  
  const [data, setData] = useState<ReportUnitResource[]>();
  const { project, unit, projectResources } = props;
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (projectResources) {
      setIsLoading(true);
      let fetchArr = projectResources.map((projectResource) => {
        return api.reports.resource(projectResource).quality().then((report) => report);
      });
      Promise.all(fetchArr).then((results) => {
        setData(results);
        setIsLoading(false);
      });
    }
    else {
      setData(undefined);
    }
  }, [projectResources]);

  useEffect(() => {
    if (data) {
      // setIsLoading(true);
      // api.reports.resource(projectResource).quality().then((data) => {
      // setData(data);
      setScore(data.map(d => d.score).reduce((a,b)=>a+b) / data.length / 100.0);
      setChartOptions({
        chart: {
          type: 'bar',
        },

        title: {
          text: 'Overall Quality Scores',
        },

        xAxis: {
          //categories: Object.entries(data.scores).map(([name, value]) => name),
          categories: data.map(qualityReport => qualityReport.name),
          title: {
            text: null,
          },
          gridLineWidth: 1,
        },
        legend: {
          enabled: false,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          floating: true,
          shadow: true,
        },
        plotOptions: {
          bar: {
            borderRadius: '50%',
            dataLabels: {
              enabled: true
            },
            groupPadding: 0.1
          }
        },
        yAxis: [
          {
            min: 0,
            max: 100,
            id: 'quality',
            // lineWidth: 0,
            // opposite: true,
            labels: {
              overflow: 'justify'
            },
            gridLineWidth: 0,
          },
        ],
        series: [
          {
            // yAxis: 0,
            type: 'bar',
            name: 'Score',
            //Object.entries(data.scores).map(([name, value]) => Math.round(value * 100) / 100),
            data: data.map((qualityScore) => qualityScore.score),
            //color: 'blue',
            tooltip: {
              valueSuffix: '%',
            }
          },

        ]

      });
      // setIsLoading(false);
      // });
    }
    else {
      // setData(undefined);
      setScore(undefined);
      setChartOptions(undefined);
    }
  }, [unit, data]);

  return (<><Panel isLoading={isLoading}>
    <PanelHeader>Overall Quality Score</PanelHeader>
    <PanelBody>
      <div className="row">
        {score && <div className="col-xl-3 col-md-6">
          <div className={"widget widget-stats h-100 " + colorForScore(score)}>
            <div className="stats-icon"><i className="fa fa-star"></i></div>
            <div className="stats-info">
              <h4>Average Score</h4>
              <p>{format.percentage(score)}</p>
            </div>
          </div>
        </div>}
        {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading chart data...</p>}
        {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
      </div>
    </PanelBody>
  </Panel>
    {project && unit && data && data.map((pr) => <ProjectResourceReport key={pr.resourceId} project={project} unit={unit} data={pr} />)}
  </>);
}
type ProjectResourceReportProps = {
  project: Project;
  unit: GradeUnit;
  // projectResource: ProjectResource;
  data?: ReportUnitResource;
};

function ProjectResourceReport(props: ProjectResourceReportProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit, data } = props;
  const [isLoading, setIsLoading] = useState(false);

  //const [anchor, setAnchor] = useState<ReportUnitResource>();
  // const [data, setData] = useState<ReportUnitResource>();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    if (data) {
      // setIsLoading(true);
      // api.reports.resource(projectResource).quality().then((data) => {
        // setData(data);
        setChartOptions({
          chart: {
            type: 'bar',
          },

          title: {
            text: 'Quality Scores',
          },
          subtitle: {
            text: data.name,
          },

          xAxis: {
            categories: Object.entries(data.scores).map(([name, value]) => name),
            title: {
              text: null,
            },
            gridLineWidth: 1,
          },
          legend: {
            enabled: false,
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            floating: true,
            shadow: true,
          },
          plotOptions: {
            bar: {
              borderRadius: '50%',
              dataLabels: {
                enabled: true
              },
              groupPadding: 0.1
            }
          },
          yAxis: [
            {
              min: 0,
              max: 100,
              id: 'quality',
              // lineWidth: 0,
              // opposite: true,
              labels: {
                overflow: 'justify'
              },
              gridLineWidth: 0,
            },
          ],
          series: [
            {
              // yAxis: 0,
              type: 'bar',
              name: 'Score',
              data: Object.entries(data.scores).map(([name, value]) => Math.round(value * 100) / 100),
              //color: 'blue',
              tooltip: {
                valueSuffix: '%',
              }
            },

          ]

        });
        // setIsLoading(false);
      // });
    }
    else {
      // setData(undefined);
      setChartOptions(undefined);
    }
  }, [unit, data]);

  // Process report data
  // useEffect(() => {
  //   if (chartOptions) {
  //     console.log('chartOptions', chartOptions);
  //   }
  //   else {
  //     setChartOptions(undefined);
  //   }
  // }, [chartOptions]);


  return (<Panel isLoading={isLoading}>
    <PanelHeader>{data?.name}</PanelHeader>
    <PanelBody>
      <div className="row">
        {data && <div className="col-xl-3 col-md-6">
          <div className={"widget widget-stats h-100 " + colorForScore(data.score)}>
            <div className="stats-icon"><i className="fa fa-star"></i></div>
            <div className="stats-info">
              <h4>Average Score</h4>
              <p>{format.percentage(data.score / 100.0)}</p>
            </div>
          </div>
        </div>}
        {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading chart data...</p>}
        {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
      </div>
    </PanelBody>
  </Panel>);
}

type QualityForUnitProps = {
  project: Project;
  unit: GradeUnit;
  data: ReportUnit;
};
function QualityForUnit(props: QualityForUnitProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit, data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    if (project) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        chart: {
          polar: true,
          // type: 'line',
        },

        title: {
          text: 'Quality vs. Topic Unity',
          //text: `Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`
        },
        pane: {
          size: '85%',
        },

        xAxis: {
          categories: data.resources.map(resource => resource.name),
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
        },
        yAxis: [
          {
            id: 'unity',
            min: 0,
            max: data.anchor?.topics.length ?? 4,
            lineWidth: 0,
          },
          {
            min: 0,
            max: 100,
            id: 'quality',
            lineWidth: 0,
            opposite: true,
          },
        ],
        series: [
          // {
          //   type: 'column',
          //   name: 'Domain Unity',
          //   data: data.resources.map(resource => (data.anchor?.domains ?? 0) - resource.domainsInCommon),
          //   //[8, 7, 6, 5, 4, 3, 2, 1],
          //   //pointPlacement: 'between'
          // },
          {
            yAxis: 0,
            type: 'column',
            name: 'Topic Unity',
            description: 'Number of topics',
            //data: data.resources.map(resource => (data.anchor?.topics ?? 0) - resource.topicsInCommon),
            data: data.resources.map(resource => resource.topicsInCommon.length),
            color: 'orange',
            tooltip: {
              valuePrefix: 'Matching ',
              valueSuffix: ' of ' + (data.anchor?.topics.length ?? 0),
              // valuePrefix: 'Not matching ',
              // valueSuffix: ' out of ' + (data.anchor?.topics ?? 0),
            }
          },
          {
            yAxis: 1,
            type: 'line',
            name: 'Quality',
            data: data.resources.map(resource => resource.score),
            color: 'blue',
            tooltip: {
              valueSuffix: '%',
            }
          },

        ]

      });

      setIsLoading(false);
    }
    else {
      setChartOptions(undefined);
    }
  }, [data.anchor?.topics, data.resources, labels.grades, project, unit.grade, unit.unit]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Quality and Topic Unity data...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}

function HeatmapForUnit(props: QualityForUnitProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit, data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  const [topics, setTopics] = useState<string[]>();

  const unique = (array: string[]) => array
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    if (project && topics) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },


        title: {
          text: 'Topic Heatmap',
          style: {
            fontSize: '1em'
          }
        },

        xAxis: {
          categories: data.resources.map(resource => resource.name),
        },

        yAxis: {
          //categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          //categories: data.resources
          //title: null,
          // data: grades.map((grade, x) => {
          //   return heatmap.topics.map((topic, y) => {
          //     const resourceCount = heatmap.resourceCounts.find((rc) => rc.grade === grade && rc.topicId === topic.id);
          //     return {
          //       x,
          //       y,
          //       value: resourceCount?.count ?? 0,
          //     };
          //   });
          // }).reduce((a, b) => a.concat(b), []),
          categories: topics,
          reversed: true,
          title: {
            text: 'Topic'
          },
        },

        // accessibility: {
        //   point: {
        //     descriptionFormat: '{(add index 1)}. ' +
        //       '{series.xAxis.categories.(x)} sales ' +
        //       '{series.yAxis.categories.(y)}, {value}.'
        //   }
        // },

        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          //maxColor: Highcharts.getOptions().colors[0]
        },

        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },

        // tooltip: {
        //   format: '<b>{series.xAxis.categories.(point.x)}</b> sold<br>' +
        //     '<b>{point.value}</b> items on <br>' +
        //     '<b>{series.yAxis.categories.(point.y)}</b>'
        // },

        series: [{
          type: 'heatmap',
          name: 'Topics by Resource',
          dataLabels: {
            enabled: true,
            color: '#000000'
          },
          data: [...data.resources.flatMap((resource, x) => topics.map((topic, y) => {
            
            return [x, y, resource.topics.filter(t => t === topic).length];
          }),
          )],

        }],
        // series: [{
        //   name: 'Sales per employee',
        //   borderWidth: 1,
        //   data: [[0, 0, 10], [0, 1, 19], [0, 2, 8], [0, 3, 24], [0, 4, 67],
        //   [1, 0, 92], [1, 1, 58], [1, 2, 78], [1, 3, 117], [1, 4, 48],
        //   [2, 0, 35], [2, 1, 15], [2, 2, 123], [2, 3, 64], [2, 4, 52],
        //   [3, 0, 72], [3, 1, 132], [3, 2, 114], [3, 3, 19], [3, 4, 16],
        //   [4, 0, 38], [4, 1, 5], [4, 2, 8], [4, 3, 117], [4, 4, 115],
        //   [5, 0, 88], [5, 1, 32], [5, 2, 12], [5, 3, 6], [5, 4, 120],
        //   [6, 0, 13], [6, 1, 44], [6, 2, 88], [6, 3, 98], [6, 4, 96],
        //   [7, 0, 31], [7, 1, 1], [7, 2, 82], [7, 3, 32], [7, 4, 30],
        //   [8, 0, 85], [8, 1, 97], [8, 2, 123], [8, 3, 64], [8, 4, 84],
        //   [9, 0, 47], [9, 1, 114], [9, 2, 31], [9, 3, 48], [9, 4, 91]],
        //   dataLabels: {
        //     enabled: true,
        //     color: '#000000'
        //   }
        // }],

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              yAxis: {
                labels: {
                  format: '{substr value 0 1}'
                }
              }
            }
          }]
        }
      }
      );

      setIsLoading(false);
      //});

    }
    else {
      setChartOptions(undefined);
    }
  }, [data.resources, labels.grades, project, topics, unit.grade, unit.unit]);

  useEffect(() => {
    if (data) {
      setTopics(unique(data.resources.flatMap(resource => resource.topics)));
    }
    else {
      setTopics(undefined);
    }
  }, [data]);
  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Resource Heatmap...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}