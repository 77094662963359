import React from "react";
import Attribute from "../../models/attribute";
import AttributeTypes from "../../models/attributeTypes";
import AttributeValue from "../../models/attributeValue";

type AttributeValueSummaryProps = {
  attribute: Attribute;
  attributeValues?: AttributeValue[];
}
export function AttributeValueSummary(props: AttributeValueSummaryProps) {
  const { attribute, attributeValues } = props;
  const attributeValue = attributeValues?.find(values => values.id === attribute.id);
  if (attributeValue) {
    switch (attribute.type) {
      case AttributeTypes.YesNo:
        if (attributeValue.boolValue) {
          return (<span key={props.attribute.id}>Yes</span>);
        }
        return (<span key={props.attribute.id}>No</span>);
      case AttributeTypes.Date:
        if (attributeValue.dateValue) {
          if (!(attributeValue instanceof Date)) {
            const dateValue = new Date(attributeValue.dateValue.toString());
            return (<span key={props.attribute.id}>{dateValue.toLocaleDateString()}</span>);
          }
          else {
            return (<span key={props.attribute.id}>{attributeValue.formattedValue ?? attributeValue.dateValue.toLocaleDateString()}</span>);
          }
        }
        break;
      case AttributeTypes.String:
        if (attributeValue.stringValue) {
          return (<span key={props.attribute.id}>{attributeValue.stringValue}</span>);
        }
        break;
      case AttributeTypes.Choice:
      case AttributeTypes.MultipleChoice:
        if (attributeValue.choicesValue) {
          const choiceValues = attribute.choices?.filter((choice) => attributeValue.choicesValue?.includes(choice.id!));
          //return <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"><i className="fa fa-circle fs-9px fa-fw me-5px"></i> Paid</span>
          return (<span key={props.attribute.id} className="d-block">{choiceValues?.map((choice, index) => index === 0 ? (<span key={`choice-${choice.id}`}>{choice.label}</span>) : (<span key={`choice-${choice.id}`} className="pe-1">, {choice.label}</span>))}</span>);
        }
        break;
      case AttributeTypes.Text:
        if (attributeValue.stringValue) {
          return (<span key={props.attribute.id}>{attributeValue.stringValue}</span>);
        }
        break;
      case AttributeTypes.NumberWhole:
        if (attributeValue.intValue) {
          return (<span key={props.attribute.id}>{attributeValue.formattedValue ?? attributeValue.intValue}</span>);
        }
        break;
      case AttributeTypes.NumberDecimal:
        if (attributeValue.doubleValue) {
          return (<span key={props.attribute.id}>{attributeValue.formattedValue ?? attributeValue.doubleValue}</span>);
        }
        break;
      // case AttributeTypes.Suite:
      // case AttributeTypes.MultipleSuites:
      //   if (attributeValue.referencesValue) {
      //     return (<span key={props.attribute.id} className="text-ellipsis">{attributeValue.referencesValue?.map((entityReference) => <span className="px-1" key={entityReference.id}><Link to={generatePath(SuiteDetailsRoute, { id: `${entityReference.id}` })} className="">{entityReference.name}</Link></span>)}</span>);
      //   }
      //   break;
    }
  }
  return (<React.Fragment></React.Fragment>);
}