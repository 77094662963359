import React from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import { selectTheme } from '../../store/themeSlice';
import { useAppSelector } from '../../store/hooks';
import { Menu } from '../../store/menu';
import { selectMenu } from '../../store/menuSlice';
import { selectSession } from '../../store/sessionSlice';
import EntityTypeLabel from '../label/entityTypeLabel';

type NavItemProps = {
	menu: Menu;
};
function NavItem(props: NavItemProps) {
	const {
		menu
	} = props;
	const session = useAppSelector(selectSession);

	let resolved = useResolvedPath(menu.path);
	let match = useMatch({ path: resolved.pathname });

	let location = useLocation();
	let match2 = matchPath({ path: menu.path, end: false, }, location.pathname);

	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret = (menu.children && !menu.badge) && <div className="menu-caret"></div>;
	let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
	let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
	if (menu.entityType) {
		title = <div className="menu-text"><EntityTypeLabel entityType={menu.entityType} /> {label} {highlight}</div>;
	}

	if (menu.roles) {
		if (!menu.roles.every((requiredRole) => 
			(session.roles?.findIndex((userRole) => userRole === requiredRole) ?? -1) >= 0)
			) {
				return (<div></div>);
			}
	}

	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu.path} relative="route">
				{img} {icon} {title}{caret} {badge}
			</NavLink>

			{menu.children && (
				<div className="menu-submenu">
					{menu.children.map((submenu, i) => (
						<NavItem key={i} menu={submenu} />
					))}
				</div>
			)}
		</div>
	);
}

export default function SidebarNav() {
	const theme = useAppSelector(selectTheme);
	const menu = useAppSelector(selectMenu);
    const handleSidebarSearch = (e: React.KeyboardEvent) => {
        throw new Error('Function not implemented.');
    }

	return (
		<div className="menu">
			{theme.appSidebarSearch && (
				<div className="menu-search mb-n3">
					<input type="text" className="form-control" placeholder="Sidebar menu filter..." onKeyUp={handleSidebarSearch} />
				</div>
			)}
			<div className="menu-header">Navigation</div>
			{menu.map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
		</div>
	);
}

//export default SidebarNav;