import { generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { SearchProps, api } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import { PartnerDetailsRoute } from "./partnerDetailsScreen";
import { PartnerEditRoute } from "./partnerEditScreen";

export const PartnersRoute = "/partners";

const filters = [
  { key: 'active', label: 'Active', api: api.partners.search },
  { key: 'all', label: 'All', api: (search: SearchProps) => api.partners.search({ ...search, activeOnly: false, }) },
];

export default function PartnersScreen() {
  const navigate = useNavigate();

  return <RecordsListScreen
    type={EntityTypes.Partner}
    filters={filters}
    actions={(record) => <>
      <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(PartnerDetailsRoute, { id: `${record.id}` }))}>Details</button>
      <button className="btn btn-sm btn-outline-secondary mx-1" onClick={() => navigate(generatePath(PartnerEditRoute, { id: `${record.id}` }))}>Edit</button>
      </>}
     />;
}