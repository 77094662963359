import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import EntityTypes, { EntityTypeKeys } from '../models/entityTypes';

export interface TabState {
    tab: EntityTypes;
}
export interface RecordListState {
    selectedFilter: string;
}
export interface UiState {
    projectDetails: TabState;
    resourceDetails: TabState;
    recordList: { [n in EntityTypes as number]?: RecordListState };
    review: TabState;
}

const initialState: UiState = {
    projectDetails: {
        tab: EntityTypes.User,
    },
    resourceDetails: {
        tab: EntityTypes.ProjectResource,
    },
    recordList: {},
    review: {
        tab: EntityTypes.Tag,
    },
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setResourceDetailsTab: (state, action: PayloadAction<TabState>) => {
            state.resourceDetails = {
                ...action.payload
            };
        },
        setProjectDetailsTab: (state, action: PayloadAction<TabState>) => {
            state.projectDetails = {
                ...action.payload
            };
        },
        setReviewTab: (state, action: PayloadAction<TabState>) => {
            state.review = {
                ...action.payload
            };
        },
        setRecordListFilter: {
            reducer: (state, action: PayloadAction<{ entity: EntityTypes, filterKey: string }>) => {
                state.recordList[action.payload.entity] = {
                    ...state.recordList[action.payload.entity],
                    selectedFilter: action.payload.filterKey,
                };
            },
            prepare: (type: EntityTypes, filterKey: string) => {
                return {
                    payload: {
                        entity: type,
                        filterKey,
                    }
                };
            }
        }

        //increment: (state) => {
        //  // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //  // doesn't actually mutate the state because it uses the Immer library,
        //  // which detects changes to a "draft state" and produces a brand new
        //  // immutable state based off those changes
        //  state.value += 1;
        //},
        //decrement: (state) => {
        //  state.value -= 1;
        //},
        //// Use the PayloadAction type to declare the contents of `action.payload`
        //incrementByAmount: (state, action: PayloadAction<number>) => {
        //  state.value += action.payload;
        //},
    },
});

export const { setResourceDetailsTab, setProjectDetailsTab, setRecordListFilter, setReviewTab } = uiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUi = (state: RootState) => state.ui;

export default uiSlice.reducer;
