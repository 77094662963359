import { Link, generatePath, useNavigate } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import RecordsListScreen from "../recordsListScreen";
import { useEffect, useState } from "react";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import useLabel from "../../util/useLabel";
import Tag from "../../models/tag";
import { TagDetailsRoute } from "./tagScreen";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagEditRoute } from "./tagEditScreen";

export const TagsRoute = "/settings/tags";


export default function TagsScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const label = useLabel(EntityTypes.Tag);
  const filters = [
    { key: 'domains', label: 'Domains', api: api.settings.tags.searchDomains },
    { key: 'all', label: `All ${label.plural}`, api: api.settings.tags.search },
  ];
  const [selectionFilter, setSelectionFilter] = useState<RecordFilter<Tag>[]>(filters);
  const [parentTag, setParentTag] = useState<Tag>();

  useEffect(() => {
    document.title = 'Tags';
  }, []);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Settings</li>
          <li className="breadcrumb-item active"><Link to={TagsRoute}>{label.plural}</Link></li>
          {parentTag && <>
            <li className="breadcrumb-item">{parentTag.label}</li>
            <li className="breadcrumb-item active">Sub-{label.plural}</li>
          </>}
        </ul>
      </div>
      <div className="ms-auto">
        <Link to={'create'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create {label?.singular}</Link>
      </div>
    </div>
    <RecordsList
      type={EntityTypes.Tag}
      //nameLabel={props.nameLabel}
      detailsRoute={TagDetailsRoute}
      //editRoute={props.editRoute}
      filters={selectionFilter}
      //actions={props.actions}
      actions={
        (record: Tag) => <>
          <Link to={generatePath(TagDetailsRoute, { id: `${record.id}` })} className="btn btn-sm btn-outline-primary me-2">Details</Link>
          <Link to={generatePath(TagEditRoute, { id: `${record.id}` })} className="btn btn-sm btn-outline-warning">Edit</Link>
        </>
      }
      showName={false}
      //sort={props.sort}
      //columns={props.columns}
      columns={[
        { label: 'Label', callback: (record) => <td><Link to={generatePath(TagDetailsRoute, { id: `${record.id}` })}>{record.label}</Link></td>, },
        { label: 'Type(s)', callback: (record) => <td>{AllKnowledgeMapTypes.filter((kmType) => (kmType & (record.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}</td>, },
        { label: `Parent ${label.singular}`, callback: (record) => <td>{record.parentTag && <Link to={generatePath(TagDetailsRoute, { id: `${record.parentTagId}` })}>{record.parentTag?.label}</Link>}</td>, },
      ]}
    />
  </div>);
}