import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { handleSetAppDarkMode, handleSetAppGradientEnabled, handleSetAppHeaderFixed, handleSetAppHeaderInverse, handleSetAppSidebarFixed, handleSetAppSidebarGrid, handleSetAppTheme, selectTheme, Themes } from '../../store/themeSlice';

export default function ThemePanel() {
	const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();
	const themeList: Themes[] = ['red', 'pink', 'orange', 'yellow', 'lime', 'green', 'teal', 'cyan', 'blue', 'purple', 'indigo', 'dark'];
	const [expand, setExpand] = useState<boolean>(false);
	//constructor(props) {
	//	super(props);

	//	this.state = {
	//		expand: false,
	//		theme: (localStorage && typeof localStorage.appTheme !== 'undefined') ? localStorage.appTheme : 'teal',
	//		darkMode: false
	//	}
	//	this.theme = ['red', 'pink', 'orange', 'yellow', 'lime', 'green', 'teal', 'cyan', 'blue', 'purple', 'indigo', 'dark'];
	//}

	const handleDarkMode = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppDarkMode(true));
		} else {
			dispatch(handleSetAppDarkMode(false));
		}
	}

	const handleHeaderFixed = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppHeaderFixed(true));
		} else {
			dispatch(handleSetAppHeaderFixed(false));
		}
	}

	const handleSidebarFixed = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppSidebarFixed(true));
		} else {
			dispatch(handleSetAppSidebarFixed(false));
		}
	}

	const handleHeaderInverse = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppHeaderInverse(true));
		} else {
			dispatch(handleSetAppHeaderInverse(false));
		}
	}

	const handleSidebarGrid = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppSidebarGrid(true));
		} else {
			dispatch(handleSetAppSidebarGrid(false));
		}
	}

	const handleGradientEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(handleSetAppGradientEnabled(true));
		} else {
			dispatch(handleSetAppGradientEnabled(false));
		}
	}

	const toggleTheme = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, theme: any) => {
		e.preventDefault();
		dispatch(handleSetAppTheme(theme));
		//this.setState(state => ({
		//	theme: theme
		//}));
	}

	return (
		<div className={'theme-panel ' + (expand ? 'active' : '')}>
			<a href="#0" onClick={e => setExpand(!expand)} className="theme-collapse-btn"><i className="fa fa-cog"></i></a>
			<div className="theme-panel-content" data-scrollbar="true" data-height="100%">
				<h5>App Settings</h5>

				<div className="theme-list">
					{themeList.map((thisTheme, i) => (
						<div key={i} className={'theme-list-item ' + ((theme.appTheme === thisTheme) ? 'active' : '')}>
							<a href="#0" onClick={e => toggleTheme(e, thisTheme)} className={'theme-list-link bg-' + thisTheme}>&nbsp;</a>
						</div>
					))}
				</div>

				<div className="theme-panel-divider"></div>

				<div className="row mt-10px">
					<div className="col-8 control-label text-dark fw-bold">
						<div>Dark Mode <span className="badge bg-primary ms-1 py-2px position-relative" style={{ top: '-1px' }}>NEW</span></div>
						<div className="lh-14">
							<small className="text-dark opacity-50">
								Adjust the appearance to reduce glare and give your eyes a break.
							</small>
						</div>
					</div>
					<div className="col-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" name="app-theme-dark-mode" onChange={handleDarkMode} id="appThemeDarkMode" checked={theme.appDarkMode} value="1" />
							<label className="form-check-label" htmlFor="appThemeDarkMode">&nbsp;</label>
						</div>
					</div>
				</div>

				<div className="theme-panel-divider"></div>

				<div className="row mt-10px align-items-center">
					<div className="col-8 control-label text-dark fw-bold">Header Fixed</div>
					<div className="col-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" name="app-header-fixed" onChange={handleHeaderFixed} id="appHeaderFixed" value="1" checked={theme.appHeaderFixed} />
							<label className="form-check-label" htmlFor="appHeaderFixed">&nbsp;</label>
						</div>
					</div>
				</div>
				<div className="row mt-10px align-items-center">
					<div className="col-8 control-label text-dark fw-bold">Header Inverse</div>
					<div className="col-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" name="app-header-inverse" onChange={handleHeaderInverse} id="appHeaderInverse" checked={theme.appHeaderInverse} />
							<label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
						</div>
					</div>
				</div>
				<div className="row mt-10px align-items-center">
					<div className="col-8 control-label text-dark fw-bold">Sidebar Fixed</div>
					<div className="col-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" name="app-sidebar-fixed" onChange={handleSidebarFixed} id="appSidebarFixed" checked={theme.appSidebarFixed} />
							<label className="form-check-label" htmlFor="appSidebarFixed">&nbsp;</label>
						</div>
					</div>
				</div>
				<div className="row mt-10px align-items-center">
					<div className="col-8 control-label text-dark fw-bold">Sidebar Grid</div>
					<div className="col-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" onChange={handleSidebarGrid} name="app-sidebar-grid" id="appSidebarGrid" checked={theme.appSidebarGrid} />
							<label className="form-check-label" htmlFor="appSidebarGrid">&nbsp;</label>
						</div>
					</div>
				</div>
				<div className="row mt-10px align-items-center">
					<div className="col-md-8 control-label text-dark fw-bold">Gradient Enabled</div>
					<div className="col-md-4 d-flex">
						<div className="form-check form-switch ms-auto mb-0">
							<input type="checkbox" className="form-check-input" name="app-gradient-enabled" onChange={handleGradientEnabled} id="appGradientEnabled" checked={theme.appGradientEnabled} />
							<label className="form-check-label" htmlFor="appGradientEnabled">&nbsp;</label>
						</div>
					</div>
				</div>

				<div className="theme-panel-divider"></div>

				<h5>Admin Design (5)</h5>
				<div className="theme-version">
					<div className="theme-version-item">
						<a href="../template_html/index_v2.html" className="theme-version-link active">
							<span style={{ backgroundImage: 'url(/assets/img/theme/default.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_transparent/index_v2.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/transparent.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_apple/index_v2.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/apple.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_material/index_v2.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/material.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_facebook/index_v2.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/facebook.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_google/index_v2.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/google.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
				</div>

				<div className="theme-panel-divider"></div>

				<h5>Language Version (7)</h5>
				<div className="theme-version">
					<div className="theme-version-item">
						<a href="../template_html/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/html.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_ajax/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/ajax.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_angularjs/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/angular1x.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_angularjs13/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/angular10x.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="#0" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/laravel.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_vuejs/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/vuejs.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../template_reactjs/index.html" className="theme-version-link active">
							<span style={{ backgroundImage: 'url(/assets/img/version/reactjs.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="#0" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/version/dotnet.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
				</div>

				<div className="theme-panel-divider"></div>

				<h5>Frontend Design (5)</h5>
				<div className="theme-version">
					<div className="theme-version-item">
						<a href="../../../frontend/template/template_one_page_parallax/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/one-page-parallax.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../../../frontend/template/template_e_commerce/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/e-commerce.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../../../frontend/template/template_blog/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/blog.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../../../frontend/template/template_forum/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/forum.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
					<div className="theme-version-item">
						<a href="../../../frontend/template/template_corporate/index.html" className="theme-version-link">
							<span style={{ backgroundImage: 'url(/assets/img/theme/corporate.jpg)' }} className="theme-version-cover"></span>
						</a>
					</div>
				</div>

				<div className="theme-panel-divider"></div>

				<a href="https://seantheme.com/color-admin/documentation/" className="btn btn-dark d-block w-100 rounded-pill mb-10px" target="_blank" rel="noreferrer"><b>Documentation</b></a>
				<a href="#0" className="btn btn-default d-block w-100 rounded-pill" data-toggle="reset-local-storage"><b>Reset Local Storage</b></a>
			</div>
		</div>
	);
};

