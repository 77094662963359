import { ReactNode, useEffect, useState } from "react";
import { Control, FormProvider, SubmitHandler, useController, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Tag, { tagOrAncestorIsUserManaged } from "../../models/tag";
import KnowledgeMapTypes, { AllKnowledgeMapTypes } from "../../models/knowledgeMapTypes";
import { TagDetailsRoute } from "./tagScreen";
import { selectTags } from "../../store/configSlice";

export const TagEditRoute = "/settings/tags/:id/edit";

interface TagEditScreenParams {
  id?: number;
  parentTagId?: number;
}

export default function TagEditScreen() {
  const navigate = useNavigate();
  const labels = useAppSelector(selectLabels);
  const allTags = useAppSelector(selectTags);
  const label = labels.tag;
  const entityApi = api.settings.tags;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parentTag, setParentTag] = useState<Tag>();
  const [defaultValue, setDefaultValue] = useState<Tag>();
  const [error, setError] = useState<String>();
  const [isParentUserManaged, setIsParentUserManaged] = useState<boolean>(false);

  const params = useParams<keyof TagEditScreenParams>();

  const form = useForm<Tag>();

  const { register, handleSubmit, reset, formState: { errors }, control } = form;

  const onSubmit: SubmitHandler<Tag> = data => {
    console.log(data);
    const request: Tag = {
      ...data,
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        setIsLoading(false);
        setError(undefined);
        if (isEditing) {
          navigate(generatePath(TagDetailsRoute, { id: `${record.id}` }));
        }
        else {
          // When creating, navigate to the parent Tag when save is complete for a "rapid-add" workflow
          navigate(generatePath(TagDetailsRoute, { id: `${record.parentTagId ?? record.id}` }));
        }
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };

  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      entityApi.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      setDefaultValue({
        isActive: true,
        parentTagId: parentTag?.id,
        knowledgeMapTypes: parentTag?.knowledgeMapTypes ?? (KnowledgeMapTypes.ELA | KnowledgeMapTypes.SocailStudies),
      });
    }
  }, [entityApi, label.singular, params.id, parentTag?.id, parentTag?.knowledgeMapTypes]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (defaultValue) {
      reset({
        ...defaultValue,
      }, {

      });
    }
  }, [defaultValue, reset]);

  useEffect(() => {
    //tagOrAncestorIsUserManaged(tag)
    if (defaultValue?.parentTagId) {
      const parentTag = allTags?.find(t => t.id === defaultValue.parentTagId);
      if (allTags && parentTag) {
        setIsParentUserManaged(tagOrAncestorIsUserManaged(allTags, parentTag));
      }

    }
  }, [allTags, defaultValue?.parentTagId]);

  useEffect(() => {
    if (params.parentTagId || defaultValue?.parentTagId) {
      entityApi.get(defaultValue?.parentTagId ?? parseInt(params.parentTagId!))
        .then((record) => {
          setParentTag(record);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.parentTagId}`);
        });
    }
  }, [defaultValue?.parentTagId, entityApi, label.singular, params.parentTagId]);

  const { field: knowledgeMapTypesField } = useController({
    name: `knowledgeMapTypes` as const,
    control,
  });

  return (<div>
    <ol className="breadcrumb float-xl-end">
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      {isEditing && <li className="breadcrumb-item active">Edit</li>}
      {!isEditing && <li className="breadcrumb-item active">Create</li>}
    </ol>
    <h1 className="page-header">{label.singular} Details</h1>
    <div className="row">
      {parentTag && <div className="col-md-4">
        <div className="card border-0 mb-4">
          <div className="card-header p-3 h6 m-0 d-flex align-items-center">
            <ol className="breadcrumb">
              {tagBreadcrumb(parentTag)}
            </ol>
          </div>
          <div className="card-body">
            {parentTag?.label && <h6 className="card-subtitle text-muted">Parent {label.singular}</h6>}
            {parentTag?.label && <p className="card-text">{parentTag?.label}</p>}
            {parentTag.description && <h6 className="card-subtitle text-muted">Description</h6>}
            {parentTag.description && <p className="card-text">
              {parentTag.description}
            </p>}
            <h6 className="card-subtitle text-muted">Knowledge Map Type(s)</h6>
            <div className="card-text">
              {AllKnowledgeMapTypes.filter((kmType) => (kmType & (parentTag.knowledgeMapTypes ?? KnowledgeMapTypes.Undefined)) === kmType).map((kmType) => <div key={kmType}>{labels.knowledgeMapTypes[kmType]}</div>)}
            </div>
          </div>
        </div>
      </div>}
      <div className="col-md-8">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Label</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.label ? 'is-invalid' : '')} {...register("label", { required: true })} />
                    {errors.label && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Description</label>
                  <div className="col-md-9">
                    <textarea rows={4} className={"form-control mb-5px " + (errors.label ? 'is-invalid' : '')} {...register("description")} />
                    {errors.description && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Knowledge Map Type(s)</label>
                  <div className="col-md-9 pt-2">
                    {AllKnowledgeMapTypes.map((kmType) => <div key={kmType} className="form-check mb-2 form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={kmType}
                          checked={((knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) & kmType) === kmType}
                          onChange={(e) => {
                            const valueCopy = (e.target.checked)
                              ? (knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) | kmType
                              : (knowledgeMapTypesField.value ?? KnowledgeMapTypes.Undefined) ^ kmType; // XOR
                            // console.log('knowledgeMapTypesField', knowledgeMapTypesField.value, valueCopy);
                            knowledgeMapTypesField.onChange(valueCopy);
                          }}
                        />
                        {labels.knowledgeMapTypes[kmType]}
                      </label>
                    </div>)}

                  </div>
                </div>


                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Allow Users to add Sub-Tags</label>
                  <div className="col-md-9 pt-2">
                    {isParentUserManaged && <p>This tag's ancestor allows sub-tags to be added by users.</p>}
                    {!isParentUserManaged &&
                      <div className="form-check form-switch mb-2">
                        <input className="form-check-input" type="checkbox" id="usersCanAddSubTags" {...register("usersCanAddSubTags")} />
                        <LabelUsersCanAddSubtags control={control} />
                      </div>
                    }
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Status</label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                      <label className="form-check-label" htmlFor="isActive">Active</label>
                    </div>
                  </div>
                </div>

                <div className="row mb-0">
                  <div className="offset-md-3 col-md-9 pt-2">
                    <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </form>
        </FormProvider>
      </div>
    </div>
  </div>);
}


function tagBreadcrumb(record: Tag | undefined): ReactNode {
  //{record?.parentTag && <li className="breadcrumb-item"><Link to={generatePath(TagDetailsRoute, { id: `${record.parentTagId}` })}>{record.parentTag.label}</Link></li>}
  return <>{record?.parentTag && tagBreadcrumb(record.parentTag)}
    <li className="breadcrumb-item"><Link to={generatePath(TagDetailsRoute, { id: `${record?.id}` })}>{record?.label}</Link></li>
  </>;
}

function LabelUsersCanAddSubtags({ control }: { control: Control<Tag> }) {
  const isAllowed = useWatch({
    control,
    name: "usersCanAddSubTags",
  })

  if (isAllowed) {
    return <label className="form-check-label" htmlFor="usersCanAddSubTags">Allow</label>
  }

  return <label className="form-check-label" htmlFor="usersCanAddSubTags">Not Allowed</label>
}