import { useCallback, useEffect, useState } from "react";
import Tag, { tagHasAncestorWithId, tagHasDescendantwithId } from "../../models/tag";
import { selectTags } from "../../store/configSlice";
import { useAppSelector } from "../../store/hooks";
import { TagSelector } from "./tagSelector";
import { selectLabels } from "../../store/labelsSlice";
import useDebounce from "../../util/useDebounce";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import QuickAddTag from "../modal/quickAddTag";

type TagBrowserProps = {
  //selectedTags: Tag[];
  selectedTagIds: number[];
  availableTagIds?: number[];
  onAdd: (tag: Tag) => void;
  // onAdd: (tagId: number) => void;
  // onRemove: (tag: Tag) => void;
  onRemove: (tagId: number) => void;
  knowledgeMapType: KnowledgeMapTypes;
};

export function TagBrowser(props: TagBrowserProps) {
  const labels = useAppSelector(selectLabels);
  const tags = useAppSelector(selectTags);
  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 800);
  const [addSubTag, setAddSubTag] = useState<Tag>();
  const [showQuickAddTag, setShowQuickAddTag] = useState<boolean>(false);

  // recursive search of tags
  const matchesSearch = useCallback((tag: Tag, term: string): boolean => {
    if (tag.label?.toLowerCase().includes(term)) {
      return true;
    }
    // check sub-tags for a match
    return tags?.filter(t => t.parentTagId === tag.id).some((t) => matchesSearch(t, term)) ?? false;
  }, [tags]);

  useEffect(() => {
    if (tags) {
      const filteredTags = tags
        // filter tags by Knowledge Maps Type
        .filter((tag, index, array) => tag.isActive && tag.knowledgeMapTypes && ((tag.knowledgeMapTypes & props.knowledgeMapType) === props.knowledgeMapType))
        // filter by availableTags
        .filter((tag, index, array) => props.availableTagIds === undefined || (
            tagHasDescendantwithId(array, tag, props.availableTagIds)
            || tagHasAncestorWithId(array, tag, props.availableTagIds)
            ))
         ?? [];
      if (debouncedSearchTerm.length > 2) {
        setAvailableTags(filteredTags.filter((t) => matchesSearch(t, debouncedSearchTerm.toLowerCase())));
      }
      else {
        setAvailableTags(filteredTags);
      }
    }
  }, [debouncedSearchTerm, matchesSearch, props.availableTagIds, props.knowledgeMapType, tags]);

  // const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const onSearch = () => {

  };

  const onQuickAdd = (parentTag: Tag): void => {
    console.log('onQuickAdd', parentTag);
    setAddSubTag(parentTag);
    setShowQuickAddTag(true);
  };

  return <div><div className="card">
    <div className="card-header d-flex flex-row align-items-center">
      <div className="flex-grow-1">Available {labels.tag.plural}</div>
      <div className="form-group position-relative"><input type="text" onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); onSearch(); } }} className="form-control" placeholder="Enter keyword" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /><button type="button" className="btn btn-search bottom-0 top-0 end-0 position-absolute"><i className="fa fa-search"></i></button></div>
    </div>
    <div className="card-body">
      {availableTags.filter(t => !(t.parentTagId)).map((tag) => <TagSelector
        key={tag.id}
        selectedTagIds={props.selectedTagIds}
        tags={availableTags}
        tag={tag}
        onAdd={props.onAdd}
        onRemove={props.onRemove}
        hightlight={debouncedSearchTerm}
        onQuickAdd={onQuickAdd}
      />)}
    </div>
  </div>
    {addSubTag && <QuickAddTag parentTag={addSubTag} show={showQuickAddTag} onSuccess={(tag) => { setShowQuickAddTag(false); props.onAdd(tag); }} onCancel={() => { setShowQuickAddTag(false); }} />}
  </div>;
}