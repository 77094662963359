import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { config } from '../config';
import Account from '../models/account';

interface IdentityResponse {
	id?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	initials?: string;
	timeZone?: string;
	userName?: string;
	account?: Account;
	roles?: string[];
}


export interface SessionState extends IdentityResponse {
	isLoggedIn: boolean;
	loading: boolean;
	error: any;
}

const initialState: SessionState = {
	isLoggedIn: false,
	loading: true,
	error: null,
};


export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		hydrate: (state, action) => {
			// do not do state = action.payload it will not update the store
			return action.payload
		},
		loginRequired: (state) => {
			state.isLoggedIn = false;
			state.loading = false;
			state.error = null;
		},
		loggedIn: (state, action: PayloadAction<number>) => {
			state.isLoggedIn = false;
			state.loading = false;
			state.error = null;
		},
		switchToAccount: (state, action: PayloadAction<Account>) => {
			state.account = action.payload;
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(logout.pending, (state) => {
				state.loading = true;
			})
			.addCase(logout.fulfilled, () => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
				};
			})
			.addCase(logout.rejected, (state) => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
					error: "Error logging out",
				};
			})
			.addCase(identity.pending, (state) => {
				state.loading = true;
			})
			.addCase(identity.fulfilled, (state, action) => {
				return {
					...state,
					loading: false,
					isLoggedIn: true,
					...action.payload,
				};
			})
			.addCase(identity.rejected, (state) => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
					error: "Error getting identity",
				};
			});
	},
});


export const { loginRequired, loggedIn, switchToAccount, hydrate } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSession = (state: RootState) => state.session;
export const selectAccount = (state: RootState) => state.session.account;

export const logout = createAsyncThunk('session/logout', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "POST",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/identity/logout`, requestOptions);
	return response.ok;
});

export const identity = createAsyncThunk('session/identity', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "GET",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/identity`, requestOptions);
	return (await response.json()) as IdentityResponse;
});

export default sessionSlice.reducer;
