import { useEffect } from 'react';
import './App.css';
import ThemePanel from './components/theme-panel/theme-panel';
import Header from './components/header/header';
import Sidebar from './components/sidebar/sidebar';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import { Content } from './components/content/content';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectTheme } from './store/themeSlice';
import { Navigate } from 'react-router-dom';
import { identity } from './store/sessionSlice';
import { fetchLabels } from './store/labelsSlice';
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { fetchTags } from './store/configSlice';


type AppProps = {
	/// If true, the Account is required and passed via Context. If no account is specified, redirects to the Account selector
	account?: boolean;
}
function App(props: AppProps) {
	const dispatch = useAppDispatch();
	const theme = useAppSelector(selectTheme);
	const session = useAppSelector(state => state.session);

	useEffect(() => {
		dispatch(identity());
		dispatch(fetchLabels());
	}, [dispatch]);

	useEffect(() => {
		if (session.isLoggedIn) {
			dispatch(fetchTags());
		}
	}, [dispatch, session.isLoggedIn]);

	if (session.loading) {
		console.warn("loading", process.env);
		return <div />;
	}


	if (!session.isLoggedIn) {
		// Redirect user to login screen
		console.warn("login required");
		return (<Navigate to={"/login?return=" + encodeURI(window.location.href)} replace />);
	}

	// if (props.account === true && !session.account) {
	// 	console.warn("account required");
	// 	//debugger;
	// 	return (<Navigate to={AccountsScreenRoute} replace />);
	// }
	// console.warn("app", props.account);
	return (<div className={
		'app ' +
		(theme.appGradientEnabled ? 'app-gradient-enabled ' : '') +
		(theme.appHeaderNone ? 'app-without-header ' : '') +
		(theme.appHeaderFixed && !theme.appHeaderNone ? 'app-header-fixed ' : '') +
		(theme.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
		(theme.appSidebarNone ? 'app-without-sidebar ' : '') +
		(theme.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
		(theme.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
		(theme.appSidebarLight ? 'app-with-light-sidebar ' : '') +
		(theme.appSidebarMinify ? 'app-sidebar-minified ' : '') +
		(theme.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
		(theme.appTopMenu ? 'app-with-top-menu ' : '') +
		(theme.appContentFullHeight ? 'app-content-full-height ' : '') +
		(theme.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
		(theme.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
		(theme.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') +
		(theme.hasScroll ? 'has-scroll ' : '')
	}>
		{!theme.appHeaderNone && (<Header />)}
		{!theme.appSidebarNone && (<Sidebar />)}
		{theme.appSidebarTwo && (<SidebarRight />)}
		{theme.appTopMenu && (<TopMenu />)}
		{!theme.appContentNone && (<Content />)}
			<ThemePanel />
	</div>);
  //return (
	 // <AppSettings.Provider value={theme.>
		//  <div className={
		//	  'app ' +
		//	  (theme.appGradientEnabled ? 'app-gradient-enabled ' : '') +
		//	  (theme.appHeaderNone ? 'app-without-header ' : '') +
		//	  (theme.appHeaderFixed && !theme.appHeaderNone ? 'app-header-fixed ' : '') +
		//	  (theme.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
		//	  (theme.appSidebarNone ? 'app-without-sidebar ' : '') +
		//	  (theme.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
		//	  (theme.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
		//	  (theme.appSidebarLight ? 'app-with-light-sidebar ' : '') +
		//	  (theme.appSidebarMinify ? 'app-sidebar-minified ' : '') +
		//	  (theme.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
		//	  (theme.appTopMenu ? 'app-with-top-menu ' : '') +
		//	  (theme.appContentFullHeight ? 'app-content-full-height ' : '') +
		//	  (theme.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
		//	  (theme.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
		//	  (theme.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') +
		//	  (theme.hasScroll ? 'has-scroll ' : '')
		//  }>
		//	  {!theme.appHeaderNone && (<Header />)}
		//	  {!theme.appSidebarNone && (<Sidebar />)}
		//	  {theme.appSidebarTwo && (<SidebarRight />)}
		//	  {theme.appTopMenu && (<TopMenu />)}
		//	  {!theme.appContentNone && (<Content />)}
		//	  <ThemePanel />
		//  </div>
	 // </AppSettings.Provider>
  //);
}

export default App;
