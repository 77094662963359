import { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import ProjectResource from "../../models/projectResource";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import Resource from "../../models/resource";
import Review from "../../models/review";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Panel, PanelBody } from "../../components/panel/panel";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import { setReviewTab } from "../../store/uiSlice";
import Tag, { tagIsAncestor } from "../../models/tag";
import { TagBrowser } from "../../components/tags/tagBrowser";
import { TagBadge } from "../../components/tags/tagBadge";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import Project from "../../models/project";
import { conditionMatch } from "../../models/attributeCondition";
import AttributeValue from "../../models/attributeValue";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import { UnitEvaluationsDetailsRoute } from "./unitEvaluationsDetailsScreen";
import UnitEvaluation from "../../models/unitEvaluation";
import GradeUnit from "../../models/gradeUnit";
import GradeTypes from "../../models/gradeTypes";
import RecordsList from "../../components/records/recordsList";
import { format } from "../../helpers/format";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";
import ProjectReviewer from "../../models/projectReviewer";

export const UnitEvaluationEditRoute = "/unitEvaluations/:id/edit";

interface UnitEvaluationEditRouteParams {
  id?: number;
  gradeUnitId?: string;
}

export default function UnitEvaluationEditScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const ui = useAppSelector(state => state.ui.review);
  const labels = useAppSelector(selectLabels);
  const tags = useAppSelector(selectConfig).tags;
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tab, setTab] = useState(EntityTypes.UnitEvaluation);
  const [gradeUnitId, setGradeUnitId] = useState<number>();
  const [gradeUnit, setGradeUnit] = useState<GradeUnit>();
  const [projectReviewer, setProjectReviewer] = useState<ProjectReviewer>();
  //const [resourceId, setResourceId] = useState<number>();
  //const [resource, setResource] = useState<Resource>();
  //const [projectId, setProjectId] = useState<number>();
  const [project, setProject] = useState<Project>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof UnitEvaluationEditRouteParams>();
  const [searchParams] = useSearchParams();

  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.UnitEvaluation];
  useEffect(() => { dispatch(fetchAttributes(EntityTypes.UnitEvaluation)) }, [dispatch]);
  const [hideAttributeIds, setHideAttributeIds] = useState<number[]>([]);

  // navigate to the tab for the URL hash e.g. #tag
  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case '#review':
          dispatch(setReviewTab({ tab: EntityTypes.Review }))
          break;
        case '#tag':
          dispatch(setReviewTab({ tab: EntityTypes.Tag }))
          break;
      }
    }
  }, [dispatch, location.hash]);

  // Form
  const [defaultValue, setDefaultValue] = useState<UnitEvaluation>();

  useEffect(() => {
    // Load the attribute and set the form with the current values
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      api.unitEvaluations.get(parseInt(params.id))
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
          //setResourceId(record.resourceId);
          setGradeUnitId(record.gradeUnitId);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.projectResource.singular + ` #${params.id}`);
        });
    }
    if (searchParams.get('gradeUnitId')) {
      setGradeUnitId(parseInt(searchParams.get('gradeUnitId')!));
    }
  }, [labels.project.singular, labels.projectResource.singular, params.id, searchParams]);

  useEffect(() => {
    if (gradeUnitId) {
      api.gradeUnit.get(gradeUnitId).then(setGradeUnit);
    }
  }, [gradeUnitId]);

  useEffect(() => {
    if (gradeUnit?.projectId) {
      api.projects.get(gradeUnit?.projectId).then(setProject);
    }
  }, [gradeUnit?.projectId]);

  // Load the Resource
  useEffect(() => {
    if (gradeUnitId) {
      api.gradeUnit.projectReviewer(gradeUnitId).then(setProjectReviewer);
    }
  }, [gradeUnitId]);

  // useEffect(() => {
  //   if (projectResourceId) {
  //     api.projectResources.get(projectResourceId)
  //       .then((record) => {
  //         setProjectResource(record);
  //         setResourceId(record.resourceId);
  //         setProjectId(record.gradeUnit?.projectId);
  //       })
  //       .catch((reason) => {
  //         console.error(reason);
  //         setError("Unable to load " + labels.projectResource.singular + ` #${params.projectResourceId}`);
  //       });
  //   }
  // }, [labels.projectResource.singular, params.projectResourceId, projectResourceId]);

  const form = useForm<Review>({
    //defaultValues: fetchRecord,
  });

  const { handleSubmit, reset, formState: { errors }, control, watch } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<UnitEvaluation> = data => {
    console.log(data);
    const request: UnitEvaluation = {
      ...data,
      projectReviewerId: projectReviewer?.id,
      gradeUnitId: gradeUnitId,
      attributes: data.attributes?.map((attrib) => {
        //debugger;
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    debugger;
    setIsLoading(true);
    (isEditing ? api.unitEvaluations.update(request) : api.unitEvaluations.create(request))
      .then((updated) => {
        setIsLoading(false);
        setError(undefined);
        navigate(generatePath(UnitEvaluationsDetailsRoute, { id: `${updated.id}` }));
      })
      .catch((reason) => {
        setError("Unable to save " + labels.unitevaluation.singular + ": " + reason);
        setIsLoading(false);
      });
  };

  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    if (params.id) {
      setIsEditing(true);
      api.unitEvaluations.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + labels.unitevaluation.singular + ` #${params.id}`);
        });
    }
    else {
      setDefaultValue({ isActive: true, name: '' });
    }
  }, [dispatch, labels.unitevaluation.singular, params.id, searchParams]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });
      //setSelectedTagIds(defaultValue.tags?.map(t => t.tagId) ?? []);
    }
  }, [attributes, replace, defaultValue, reset]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  useEffect(() => {
    // Process conditional fields
    if (attributes && gradeUnit && project) {
      const attributeValues: AttributeValue[] = [
        ...watchAttributes ?? [],
        ...project?.attributes ?? [],
        //...projectResource?.attributes ?? [],
        //...resource?.attributes ?? [],
      ];

      setHideAttributeIds(attributes.filter((attr) => {
        let show = (attr.visibleDefault ?? true);
        if (attr.hideIf) {
          console.log('hideIf', attr);
          if (attr.hideIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = false;
          }
        }
        if (!show && attr.showIf) {

          if (attr.showIf.some((cond) => conditionMatch(cond, attributeValues))) {
            show = true;
          }
          console.log('showIf', attr, show);
        }
        return !show;
      }).map((attr) => attr.id!));
    }
  }, [attributes, gradeUnit, project, watchAttributes]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">
          {isEditing ? 'Edit' : 'New'} {labels.unitevaluation.singular}
        </h1>
        <ul className="breadcrumb">
          {project?.id && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })}>{project?.name}</Link></li>}
          <li className="breadcrumb-item">Grade {labels.grades[gradeUnit?.grade ?? GradeTypes.NA]} Unit {gradeUnit?.unit}</li>
          <li className="breadcrumb-item">{labels.unitevaluation.plural}</li>
          {isEditing && <li className="breadcrumb-item active">Edit</li>}
          {!isEditing && <li className="breadcrumb-item active">Create</li>}
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {project && <RecordDetailsCard
          record={project}
          type={EntityTypes.Project}
          displayAttributes={[
            // { label: labels.project.singular, callback: (record: Review) => <Link to={generatePath(ProjectDetailsRoute, { id: `${record?.projectId}` })}>{record?.projectName}</Link>, },
            // { label: 'Knowledge Map Type(s)', callback: (record: Review) => <div>{labels.knowledgeMapTypes[project?.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</div>, },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                {/* <Link to="#review" className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} aria-current="page">{labels.review.singular}</Link> */}
                <button type="button" className={"nav-link " + (tab === EntityTypes.UnitEvaluation ? "active" : "")} aria-current="page" onClick={(e) => setTab(EntityTypes.UnitEvaluation)}>{labels.unitevaluation.singular}</button>
              </li>
              <li className="nav-item">
                <button type="button" className={"nav-link " + (tab === EntityTypes.Review ? "active" : "")} aria-current="page" onClick={(e) => setTab(EntityTypes.Review)}>{labels.review.plural} {(gradeUnit?.countResources ?? 0) > 0 && <span className="badge bg-warning">{gradeUnit?.countResources}</span>}</button>
              </li>
            </ul>
            {tab === EntityTypes.Review && <Panel className="card border-0" isLoading={isLoading}>
              <RecordsList
                type={EntityTypes.Review}
                showName={false}
                filters={
                  [
                    { key: 'mine', label: 'My Reviews', api: (search: SearchProps) => api.reviews.forProject({ ...search, projectId: gradeUnit!.projectId!, mine: true, grade: gradeUnit?.grade.toString(), unit: gradeUnit?.unit, }) },
                    // grades.map(grade => {
                    //   label: `Grade ${GradeTypes[grade]}`,
                    //   api: (term?: string, page?: number, perPage?: number) => api.reviewers.forProject(record!.id!, term, page, perPage, grade),
                    // }),
                  ]
                }
                columns={[
                  {
                    label: labels.resource.singular,
                    callback: (record) => <td>{record.resourceName}</td>,
                  },
                  {
                    label: labels.reviewer.singular,
                    sortKey: 'user',
                    callback: (record) => <td>{record.userName}</td>,
                  },
                  {
                    label: 'Created',
                    sortKey: 'created',
                    callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                  },
                ]}
                actions={(record) => <>
                  <Link key={`review-${record.id}`} className="btn btn-sm btn-outline-primary" to={{ pathname: generatePath(ReviewDetailsRoute, { id: record.id!.toString() }) }}>Details</Link>
                </>}
              />
            </Panel>}
            {tab === EntityTypes.UnitEvaluation && <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                {/* <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">{labels.resource.singular}</label>
                  <div className="col-md-9">
                    <input type="text" className="form-control read-only" defaultValue={resource?.name} readOnly />
                  </div>
                </div> */}

                {fields?.map((field, index) => {
                  const attribute = attributes?.find(attrib => attrib.id === field.id);
                  if (attribute) {
                    //console.log('field', attribute.name, field);
                    const show = attribute.visibleDefault ?? true;
                    if (!hideAttributeIds.includes(attribute.id!)) {
                      return (<div key={field.id} className={"row mb-15px collapse" + (show ? " show" : " show")}>
                        <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                        <div className="col-md-9">
                          <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                        </div>
                      </div>
                      );
                    }
                    // console.log('hidden attribute ', attribute);
                  }
                  return <div key={field.id}></div>;
                }
                )}
                <div className="row mb-0">
                  <div className="pt-2 text-center">
                    <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                  </div>
                </div>
              </PanelBody>
            </Panel>}


          </form>
        </FormProvider>
      </div>
    </div>
  </div>);
}
