import { useCallback, useEffect, useState } from "react";
import { Link, createSearchParams, generatePath, useLocation, useParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import Project from "../../models/project";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import { ProjectEditRoute } from "./projectEditScreen";
import { PartnerDetailsRoute } from "../partners/partnerDetailsScreen";
import { ProjectResourceList } from "../../components/project/projectResourceList";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import { ProjectResourcesRoute } from "./projectResourcesScreen";
import { ProjectResourceSummary } from "../../components/project/projectResourceSummary";
import ProjectGrade from "../../models/projectGrade";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import { setProjectDetailsTab } from "../../store/uiSlice";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import GradeTypes from "../../models/gradeTypes";
import ProjectReviewer from "../../models/projectReviewer";
import AddProjectReviewer from "../../components/project/addProjectReviewer";
import { format } from "../../helpers/format";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";
import KnowledgeMapTypes from "../../models/knowledgeMapTypes";
import ProjectResource from "../../models/projectResource";
import { ReviewsCreateRoute } from "../reviews/reviewsScreen";
import { setTitle } from "../../util/useDocumentTitle";
import { TagTree } from "../../components/tags/tagTree";
import { UnitEvaluationsCreateRoute } from "../unitEvaluations/unitEvaluationsScreen";
import { UnitEvaluationsDetailsRoute } from "../unitEvaluations/unitEvaluationsDetailsScreen";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { usePermissions } from "../../util/usePermissions";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import FileBlob from "../../models/fileBlob";


export const ProjectFlatFileRoute = "/projects/:id/export";

interface ProjectFlatFileRouteParams {
  id?: string;
}

export default function ProjectFlatFileScreen() {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Project>();
  const [error, setError] = useState<String>();
  const [downloadUri, setDownloadUri] = useState<FileBlob>();

  const { hasPermission } = usePermissions();

  const params = useParams<keyof ProjectFlatFileRouteParams>();
  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);
    api.projects.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`${labels.project.singular} Export`, record.name!]);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.project.singular + ` #${params.id}`);
      });
  }, [labels.project.singular, params.id]);

  useEffect(() => {
    if (record?.id) {
      api.project(record.id).export().then((uri) => {
        setDownloadUri(uri);
        setIsLoading(false);
      });
    }
    else {
      setDownloadUri(undefined);
    }
  }, [record]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Export {labels.project.singular}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partner?.id}` })}>{record?.partner?.name}</Link></li>
          <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${record?.id}` })}>{record?.name}</Link></li>
          <li className="breadcrumb-item active">{labels.project.singular} Export</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Project}
          // editRoute={ProjectEditRoute}
          // footer={<>
          //   <RequiresPermission permission={PermissionTypes.ProjectEdit}>
          //     <Link to={generatePath(ProjectResourcesRoute, { projectId: `${record?.id}` })} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-edit fa-lg me-2 ms-n2"></i> Add</Link>
          //   </RequiresPermission>
          // </>}
          displayAttributes={[
            { label: labels.partner.singular, callback: (record: Project) => <Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partnerId}` })}>{record?.partner?.name}</Link>, },
            { label: 'Knowledge Map Type', callback: (record: Project) => <>{labels.knowledgeMapTypes[record.knowledgeMapType ?? KnowledgeMapTypes.Undefined]}</>, },
            { label: labels.unitevaluation.plural, callback: (record: Project) => <>{record.unitEvaluations === true ? 'Yes' : 'No'}</>, },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <div className="card border-0 mb-4">
          <div className="card-body">
            {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Generating Export...</p>}
            {downloadUri?.url && <div className="d-flex flex-row justify-content-around">
              <a href={downloadUri.url} className="btn btn-lg btn-outline-success"><i className="fa-solid fa-download"></i> Download Export</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>);
}
