import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../../store/themeSlice';
//import React from 'react';
//import { AppSettings } from '../../config/app-settings.js';

///*
//function setTitle(path, routeArray) {
//	var appTitle;
//	for (var i=0; i < routeArray.length; i++) {
//		if (routeArray[i].path === path) {
//			appTitle = 'Color Admin | ' + routeArray[i].title;
//		}
//	}
//	document.title = (appTitle) ? appTitle : 'Color Admin | React App';
//}*/

//type ContentProps = {
//	title: string;
//	children?:
//	| React.ReactChild
//	| React.ReactChild[];
//};
//class Content extends React.Component<ContentProps> {
//	componentDidMount() {
//		//setTitle(this.props.history.location.pathname, routes);
//	}
//	//componentWillMount() {
//    //this.props.history.listen(() => {
//			//setTitle(this.props.history.location.pathname, routes);
//    //});
//  //}

//	render() {
//		return (
//			<AppSettings.Consumer>
//				{({appContentClass}) => (
//					<div className={'app-content '+ appContentClass}>
//						{this.props.children}
//					</div>
//				)
//			}
//			</AppSettings.Consumer>
//		)
//	}
//}

//export default Content;


export function Content() {
	const theme = useAppSelector(selectTheme);
	return (
		<div className={"app-content " + theme.appContentClass}>
			<Outlet />
		</div>
	);
}