import Roles from "./roles";








export enum PermissionTypes {
  ProjectEdit,
  ProjectReviewersAssign,
  ProjectReviewersList,
  ProjectReviewsListMine,
  ProjectReviewsListAll,
  ProjectResourcesCreate,
  ProjectResourcesEdit,
  ProjectUnitEvaluationsListMine,
  ProjectUnitEvaluationsListAll,
  ReviewEditMine,
  ReviewEditAll,
  ResourceReviewsListAll,
};


export const roleHasPermission = (role: Roles, permission: PermissionTypes): boolean => {

  // Permissions everyone has
  switch (permission) {
    case PermissionTypes.ProjectReviewsListMine:
    case PermissionTypes.ProjectUnitEvaluationsListMine:
    case PermissionTypes.ReviewEditMine:
      return true;
    default:
        break;
  }
  switch (role) {
    case Roles.Admin:
      return true;
    case Roles.Analyst:
      switch (permission) {
        case PermissionTypes.ProjectEdit:
        case PermissionTypes.ProjectReviewersList:
        case PermissionTypes.ProjectReviewersAssign:
        case PermissionTypes.ProjectReviewsListAll:
        case PermissionTypes.ProjectResourcesCreate:
        case PermissionTypes.ProjectResourcesEdit:
        case PermissionTypes.ProjectUnitEvaluationsListAll:
        case PermissionTypes.ReviewEditAll:
          return true;
        default:
            break;
      }
      break;
    // eslint-disable-next-line no-fallthrough
    case Roles.Reviewer:
        break;
  }
  

  return false;
};