import { useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import Attribute from "../../models/attribute";
import EntityTypes from "../../models/entityTypes";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import Resource from "../../models/resource";
import { ResourcesRoute } from "./resourcesScreen";
import { ResourceProjectList } from "../../components/project/resourceProjectList";
import { ResourceEditRoute } from "./resourceEditScreen";
import { setResourceDetailsTab } from "../../store/uiSlice";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import RecordsList from "../../components/records/recordsList";
import Review from "../../models/review";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";
import { format } from "../../helpers/format";
import { setTitle } from "../../util/useDocumentTitle";
import { usePermissions } from "../../util/usePermissions";
import { PermissionTypes } from "../../models/permissionTypes";

export const ResourceDetailsRoute = "/resources/:id";

interface ResourceDetailsRouteParams {
  id?: string;
}

export default function ResourceDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAppSelector(selectAccount);
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Resource>();
  // const [contacts, setContacts] = useState<Contact[] | undefined>();
  // const [leases, setLeases] = useState<Project[] | undefined>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof ResourceDetailsRouteParams>();
  const ui = useAppSelector(state => state.ui.resourceDetails);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.resources.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`${labels.resource.singular} Details`, record.name!]);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.resource.singular + ` #${params.id}`);
      });
  }, [account, labels.contact.plural, labels.resource.singular, params.id]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">{record?.name}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(ResourcesRoute)}>{labels.resource.plural}</Link></li>
          <li className="breadcrumb-item active">Details</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Resource}
          editRoute={ResourceEditRoute}
          displayAttributes={[
            // { label: labels.partner.singular, callback: (record: Resource) => <Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partnerId}` })}>{record?.partner?.name}</Link>, },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button className={"nav-link " + (ui.tab === EntityTypes.ProjectResource ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setResourceDetailsTab({ tab: EntityTypes.ProjectResource }))}>{labels.projectResource.plural}</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} onClick={(e) => dispatch(setResourceDetailsTab({ tab: EntityTypes.Review }))}><EntityTypeLabel entityType={EntityTypes.Review} /></button>
          </li>
        </ul>
        {ui.tab === EntityTypes.ProjectResource &&
          <div className="card border-0 mb-4">
            {record?.id && <ResourceProjectList resourceId={record.id} />}
          </div>}
        {ui.tab === EntityTypes.Review && record &&
          <div className="card border-0 mb-4">
            {record?.id && <RecordsList
              showName={false}
              type={EntityTypes.Review}
              filters={hasPermission(PermissionTypes.ResourceReviewsListAll)
                ?
                [
                  { key: 'all', label: 'All', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, }) },
                  { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, mine: true, }) },
                ]
                : [
                  { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, mine: true, }) },
                ]}
              columns={[
                // {
                //   label: partnerLabel.singular,
                //   callback: (record: ProjectResource) => <td><Link to={generatePath(PartnerDetailsRoute, { id: `${record.project?.partnerId}` })}>{record?.project?.partner?.name}</Link></td>
                // },
                {
                  label: labels.reviewer.singular,
                  sortKey: 'user',
                  callback: (record) => <td>{record.userName}</td>,
                },
                {
                  label: 'Created',
                  sortKey: 'created',
                  callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                },
              ]}
              actions={(record: Review) => <>
                <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ReviewDetailsRoute, { id: `${record.id}` }))}>Details</button>
              </>}

            //navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))
            />}
          </div>}
      </div>
    </div>
  </div>);
}
