import { useEffect, useState } from "react";
import { useForm, SubmitHandler, useFieldArray, useController, FormProvider, FieldArrayWithId } from "react-hook-form";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { Link, useNavigate, useParams } from "react-router-dom"
import Attribute from "../../models/attribute";
import AttributeTypes from "../../models/attributeTypes";
import EntityTypes, { AllEntityTypes, RelatedEntityTypes } from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAccount } from "../../store/sessionSlice";
import { ROUTE as AccountsRoute } from "../accounts/accountsScreen";
import { Panel, PanelBody } from "../../components/panel/panel";
import { ROUTE as AccountSettingsFieldsScreenRoute } from "./fieldsScreen";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import { Typeahead } from "react-bootstrap-typeahead";
import AttributeChoice from "../../models/attributeChoice";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import { selectLabels } from "../../store/labelsSlice";
import { labelFor } from "../../util/useLabel";
import AttributeCondition from "../../models/attributeCondition";

interface SettingsFieldScreenParams {
	id?: number;
}

export const EditFieldRoute = "/settings/fields/edit/:id";
export const CreateFieldRoute = "/settings/fields/create";
export function SettingsFieldScreen() {

	const navigate = useNavigate();
	const form = useForm<Attribute>({
		defaultValues: {
			isActive: true,
			visibleDefault: true,
		}
	});
	const { register, handleSubmit, watch, reset, formState: { errors }, control, setValue, getValues } = form;
	const { fields, append, remove, move } = useFieldArray({
		name: "choices",
		keyName: "fieldId",
		control
	});

	const { fields: showIfs, append: appendShowIf, remove: removeShowIf } = useFieldArray({
		name: "showIf",
		//keyName: "fieldId",
		control
	});
	const { fields: hideIfs, append: appendHideIf, remove: removeHideIf } = useFieldArray({
		name: "hideIf",
		//keyName: "fieldId",
		control
	});

	const onSubmit: SubmitHandler<Attribute> = data => {
		console.log(data);
		setIsLoading(true);
		(isEditing ? api.account.attributes.update(data) : api.account.attributes.create(data))
			.then((attribute) => {
				navigate(AccountSettingsFieldsScreenRoute + '#' + EntityTypes[data.entity]);
				setIsLoading(false);
			})
			.catch((reason) => {
				setIsLoading(false);
			});
	};

	const params = useParams<keyof SettingsFieldScreenParams>();

	const account = useAppSelector(selectAccount);
	const [relatedAttributes, setRelatedAttributes] = useState<Attribute[]>();
	// const [isChoiceType, setIsChoiceType] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [dependantOnAttribute, setDependantOnAttrbute] = useState<Attribute>();

	const [addShowIf, setAddShowIf] = useState(false);
	const [addHideIf, setAddHideIf] = useState(false);
	//const [showDependancy, setShowDependancy] = useState(false);


	useEffect(() => {
		// Load the attribute and set the form with the current values
		if (params.id) {
			setIsEditing(true);
			setIsLoading(true);
			api.account.attributes.get(parseInt(params.id))
				.then((attribute) => {
					api.account.attributes.forEntity(attribute.entity)
						.then((related) => {
							setRelatedAttributes(related.filter((attr) => attr.id !== attribute.id
								&& (
									attr.type === AttributeTypes.Choice
									|| attr.type === AttributeTypes.MultipleChoice
								)));
							setIsLoading(false);
							reset(attribute);
						});

				})
				.catch((reason) => {
					setIsLoading(false);
					console.error(reason);
				});
		}
		else {
			setIsLoading(false);
		}
	}, [account, params.id, reset]);


	const isChoiceType = watch('type') === AttributeTypes.Choice || watch('type') === AttributeTypes.MultipleChoice;
	const isDependantAttribute = watch('dependantOnAttributeId');

	useEffect(() => {
		if (isDependantAttribute && isDependantAttribute > 0) {
			api.account.attributes.get(isDependantAttribute).then((attr) => {
				setDependantOnAttrbute(attr);
			});
		}
	}, [isDependantAttribute]);


	useEffect(() => {
		// Add an empty choice
		if (isChoiceType && fields.length === 0) {
			append({
				label: "",
				isActive: true,
				sortOrder: 0,
			});
		}
	}, [append, fields.length, isChoiceType]);

	//uses move from useFieldArray to change the position of the form
	function handleDrag(result: DropResult, provided: ResponderProvided): void {
		if (result.destination) {
			move(result.source.index, result.destination.index);
		}
	}

	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to={AccountsRoute}>Accounts</Link></li>
				<li className="breadcrumb-item">{account?.name}</li>
				<li className="breadcrumb-item">Settings</li>
				<li className="breadcrumb-item"><Link to="..">Fields</Link></li>
				<li className="breadcrumb-item active">Edit</li>
			</ol>

			<h1 className="page-header">Create/Edit Custom Fields</h1>
			<div className="row">
				<FormProvider {...form}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Panel className="card border-0" isLoading={isLoading}>
							<PanelBody className="card-body">
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Name</label>
									<div className="col-md-9">
										<input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} />
										{errors.name && <div className="invalid-feedback">This field is required</div>}
									</div>
								</div>

								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Record Type</label>
									<div className="col-md-9">
										<select className="form-select" {...register("entity", { valueAsNumber: true, })} disabled={isEditing}>
											<option></option>
											{/* <option value={EntityTypes.Account}>Account</option> */}
											{AllEntityTypes.map((entityType) => <option value={entityType}><EntityTypeLabel entityType={entityType} /></option>)}
										</select>
										{isEditing && <small className="fs-12px text-gray-500-darker">You cannot change the record type of a field once it's been added.</small>}
									</div>
								</div>

								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Field Data Type</label>
									<div className="col-md-9">
										<select className="form-select" {...register("type", { valueAsNumber: true, })} disabled={isEditing}>
											<option></option>
											<option value={AttributeTypes.String}>String</option>
											<option value={AttributeTypes.Text}>Text</option>
											<option value={AttributeTypes.Date}>Date</option>
											<option value={AttributeTypes.YesNo}>Yes/No</option>
											<option value={AttributeTypes.Choice}>Choice</option>
											<option value={AttributeTypes.MultipleChoice}>Multiple Choice</option>
											<option value={AttributeTypes.NumberWhole}>Number (whole)</option>
											<option value={AttributeTypes.NumberDecimal}>Number (decimal)</option>
										</select>
										{isEditing && <small className="fs-12px text-gray-500-darker">You cannot change the data type of a field once it's been added.</small>}
									</div>
								</div>
								{isChoiceType && <div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Choices</label>
									<div className="col-md-9 pt-2">
										<div className="mb-2">
											{/* <input className="form-check-input" readOnly={true} type="checkbox" checked={(isDependantAttribute ?? 0) > 0} /> */}
											<label className="form-check-label" htmlFor="dependantOnAttributeId">Choice availability is dependant on field:</label>
											<select className={"form-control mb-5px " + (errors.format ? 'is-invalid' : '')} {...register("dependantOnAttributeId", { required: false, valueAsNumber: true })}>
												<option value={undefined}></option>
												{relatedAttributes?.map((attr) => <option key={`dependant-${attr.id}`} value={attr.id}>{attr.name}</option>)}
											</select>
										</div>
										<div>
											<DragDropContext onDragEnd={handleDrag}>
												<Droppable droppableId="test-items">
													{(provided, snapshot) => (
														<table className="table table-sm" ref={provided.innerRef} {...provided.droppableProps}>
															<thead>
																<tr>
																	<th>Order <i className="fa-solid fa-arrow-down-short-wide"></i></th>
																	<th>Label</th>
																	<th>Weight</th>
																	<th>Enabled</th>
																	{(isDependantAttribute ?? 0) > 0 && <th>Show When...</th>}
																	<th></th>
																</tr>
															</thead>

															<tbody {...provided.droppableProps} ref={provided.innerRef}>
																{fields.map((field, index) => {
																	setValue(`choices.${index}.sortOrder`, index);

																	return (<Draggable
																		key={`choice-[${field.id}]`}
																		draggableId={`item-${index}`}
																		index={index}
																	>
																		{(provided, snapshot) => (<tr key={field.id}
																			ref={provided.innerRef}
																			{...provided.draggableProps}>
																			<td>
																				<div className="btn btn-sm"
																					{...provided.dragHandleProps}>
																					<i className="fa-solid fa-grip-dots-vertical"></i> {index + 1}
																				</div>
																				<input
																					type="hidden"
																					placeholder=""
																					{...register(`choices.${index}.sortOrder` as const, { valueAsNumber: true, })} />
																			</td>
																			<td>
																				<input
																					placeholder="choice label"
																					{...register(`choices.${index}.label` as const, {
																						required: true
																					})}
																					className={"form-control form-control-sm " + (errors?.choices?.[index]?.label ? "is-invalid" : "")} />
																			</td>
																			<td className="col">
																				<input
																					placeholder=""
																					{...register(`choices.${index}.weight` as const, {
																						value: index,
																					})}
																					className={"form-control form-control-sm " + (errors?.choices?.[index]?.label ? "is-invalid" : "")} />
																			</td>
																			<td className="align-middle">
																				<div className="form-check form-switch">
																					<input className="form-check-input" type="checkbox" {...register(`choices.${index}.isActive`)} />
																				</div>
																			</td>
																			{(isDependantAttribute ?? 0) > 0 && <td className="col">


																				{/* <select
																				placeholder=""
																				{...register(`choices.${index}.dependantOnAttributeChoiceId` as const, {
																					valueAsNumber: true,
																				})}
																				className={"form-control form-control-sm " + (errors?.choices?.[index]?.label ? "is-invalid" : "")}
																			>
																				<option></option>
																				{dependantOnAttribute?.choices?.map((depchoice) => <option key={`dep-${dependantOnAttribute.id}-${depchoice.id}`} value={depchoice.id}>{depchoice.label}</option>)}
																			</select> */}


																				{dependantOnAttribute?.choices && <AttributeChoiceTypeahead index={index} dependant={dependantOnAttribute!} />}

																			</td>}

																			<td className="col">
																				{!field.id &&
																					<button className="btn btn-sm btn-outline-danger" type="button" onClick={() => remove(index)}>
																						<i className="fa fa-times"></i>
																					</button>}
																			</td>
																		</tr>)}
																	</Draggable>);
																})}
																{provided.placeholder}
															</tbody>
														</table>
													)}
												</Droppable>
											</DragDropContext>
										</div>
										<button
											type="button"
											className="btn btn-sm btn-secondary"
											onClick={() =>
												append({
													label: "",
													isActive: true,
													sortOrder: 0,
												})
											}
										>
											Add Choice
										</button>
									</div>
								</div>}

								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Format</label>
									<div className="col-md-9">
										{!isChoiceType && <input type="text" className={"form-control mb-5px " + (errors.format ? 'is-invalid' : '')} {...register("format", { required: false })} />}
										{isChoiceType && <select className={"form-control mb-5px " + (errors.format ? 'is-invalid' : '')} {...register("format", { required: false })}>
											<option></option>
											<option value={"radio"}>Radio Buttons</option>
											<option value={"select"}>Drop-down List</option>
											<option value={"checkbox"}>Check Boxes</option>
										</select>}
										{errors.format && <div className="invalid-feedback">{errors.format.message}</div>}
									</div>
								</div>

								<div className="row mb-0">
									<label className="form-label col-form-label col-md-3">Settings</label>
									<div className="col-md-9 pt-2">
										<div className="form-check form-switch mb-2">
											<input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
											<label className="form-check-label" htmlFor="isActive">Enabled</label>
										</div>
										<div className="form-check form-switch mb-2">
											<input className="form-check-input" type="checkbox" id="isRequired" {...register("isRequired")} />
											<label className="form-check-label" htmlFor="isRequired">Required</label>
										</div>
										<div className="form-check form-switch mb-2">
											<input className="form-check-input" type="checkbox" id="isShownDefault" {...register("isShownDefault")} />
											<label className="form-check-label" htmlFor="isShownDefault">Show in lists</label>
										</div>
										<div className="form-check form-switch mb-2">
											<input className="form-check-input" type="checkbox" id="visibleDefault" {...register("visibleDefault")} />
											<label className="form-check-label" htmlFor="visibleDefault">Visible by Default</label>
										</div>
										<div className="d-flex flex-row align-items-center mb-2">
											<span className="me-2">Show if: </span>
											{showIfs.map((showif, index) => <AttributeConditionEditor index={index} field={showif} entity={watch('entity')} onRemove={(field) => removeShowIf(index)} />)}
											{!addShowIf && <button className="btn btn-xs rounded-pill btn-outline-primary" onClick={() => setAddShowIf(true)}><i className="fas fa-plus"></i> Add</button>}
										</div>
										{addShowIf && <div className="ms-3  mb-2">
											<PromptAttributeConditionRelatedAttribute entityType={watch('entity')} onAdd={(condition) => { appendShowIf(condition); setAddShowIf(false); }} />
										</div>}
										<div className="d-flex flex-row align-items-center mb-2">
											<span className="me-2">Hide if: </span>
											{hideIfs.map((showif, index) => <AttributeConditionEditor index={index} field={showif} entity={watch('entity')} onRemove={(field) => removeShowIf(index)} />)}
											{!addHideIf && <button className="btn btn-xs rounded-pill btn-outline-primary" onClick={() => setAddHideIf(true)}><i className="fas fa-plus"></i> Add</button>}
										</div>
										{addHideIf && <div className="ms-3  mb-2">
											<PromptAttributeConditionRelatedAttribute entityType={watch('entity')} onAdd={(condition) => { appendHideIf(condition); setAddHideIf(false); }} />
										</div>}
									</div>
								</div>

								<div className="row mb-0">
									<div className="offset-md-3 col-md-9 pt-2">
										<button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
										<button type="submit" className="btn btn-default w-100px">Cancel</button>
									</div>
								</div>
							</PanelBody>
						</Panel>
					</form>
				</FormProvider>
			</div>
		</div>
	);
}

type AttributeChoiceTypeaheadProps = {
	index: number;
	dependant: Attribute;
}
function AttributeChoiceTypeahead(props: AttributeChoiceTypeaheadProps) {
	const { field } = useController({
		name: `choices.${props.index}.dependantOnAttributeChoiceIds`,
	});
	const [options, setOptions] = useState<AttributeChoice[]>(props.dependant.choices ?? []);
	const [selected, setSelected] = useState<AttributeChoice[]>([]);

	useEffect(() => {
		setOptions(props.dependant.choices ?? []);
	}, [props.dependant]);

	useEffect(() => {
		console.log("field.value", field.value);
		if (field.value) {
			// setOptions([]);
			// console.log("field.value->props.dependant.choices", props.dependant.choices);
			setSelected(props.dependant.choices?.filter((choice) => field.value.includes(choice.id!)) ?? []);
			// console.log("field.value->selected", selected);
		}
	}, [field.value, props.dependant.choices]);

	// const watchAttributes = useWatch({
	// 	control: control,
	// 	name: `attributes`
	// });


	return <Typeahead
		id={`choices.${props.index}.dependantOnAttributeChoiceIds`}
		options={options}
		labelKey="label"
		allowNew={false}
		clearButton={true}
		emptyLabel={'Any/All'}
		multiple={true}
		selected={selected}
		isLoading={false}
		onChange={(selected) => {
			const selectedAttributes = selected as Attribute[];
			console.log('onChange', selectedAttributes);
			if (selectedAttributes.length > 0) {
				field.onChange(selectedAttributes.map((selectedAttribute) => selectedAttribute.id!));
			}
			else {
				field.onChange([]);
				// setValue(`choices.${index}.dependantOnAttributeChoiceId`, undefined);
			}
		}}
	/>;
}



type AttributeReference = {
	id: number;
	relatedEntity?: EntityTypes;
	label: string;
	choices?: AttributeChoice[];
}

type PromptAttributeConditionRelatedAttributeProps = {
	entityType: EntityTypes;
	onAdd: (condition: AttributeCondition) => void;
}
function PromptAttributeConditionRelatedAttribute(props: PromptAttributeConditionRelatedAttributeProps) {
	const dispatch = useAppDispatch();
	const config = useAppSelector(selectConfig);
	const labels = useAppSelector(selectLabels);
	//const attributes = activeTab ? config.attributes[activeTab] : undefined;

	const [options, setOptions] = useState<AttributeReference[]>([]);
	const [selected, setSelected] = useState<AttributeReference[]>([]);
	const [valueOptions, setValueOptions] = useState<AttributeChoice[]>();
	const [selectedValues, setSelectedValues] = useState<AttributeChoice[]>([]);
	/// Ensure all attributes and related-entity attributes are loaded
	useEffect(() => {
		dispatch(fetchAttributes(props.entityType));
		// load related entity types
		RelatedEntityTypes(props.entityType).forEach((related) => dispatch(fetchAttributes(related)));
	}, [dispatch, props.entityType]);

	useEffect(() => {
		if (config.attributes[props.entityType]) {
			setOptions([
				...config.attributes[props.entityType]!
					.filter((attrib) => attrib.type === AttributeTypes.Choice || attrib.type === AttributeTypes.MultipleChoice)
					.map((attib) => { return { id: attib.id!, label: attib.name, choices: attib.choices } }),
			]);
			RelatedEntityTypes(props.entityType).forEach((related) => {
				if (config.attributes[related]) {
					setOptions((opts) => [
						...opts,
						...config.attributes[related]!
							.filter((attrib) => attrib.type === AttributeTypes.Choice || attrib.type === AttributeTypes.MultipleChoice)
							.map((attib) => {
								return {
									id: attib.id!,
									relatedEntity: related,
									label: labelFor(labels, related).singular + ' > ' + attib.name, choices: attib.choices,
								}
							}),
					]);
				}
			}
			);
		}
	}, [config.attributes, labels, props.entityType]);

	// Load typeahead-choice options for value selector
	useEffect(() => {
		if (selected.length === 1) {
			setValueOptions(selected[0].choices);
		}
		else {
			setValueOptions(undefined);
		}
	}, [selected]);

	const addCondition = (event: React.MouseEvent<HTMLElement>): void => {
		event.stopPropagation();
		props.onAdd({
			attributeId: selected[0].id,
			relatedEntity: selected[0].relatedEntity,
			value: { choicesValue: selectedValues.map((v) => v.id!), }
		});
		// reset form
		setSelected([]);
	}

	return <div className="d-flex flex-row align-items-center">
		<Typeahead
			//id={`choices.${props.index}.dependantOnAttributeChoiceIds`}
			options={options}
			labelKey="label"
			allowNew={false}
			clearButton={true}
			emptyLabel={'Select...'}
			multiple={false}
			selected={selected}
			isLoading={false}
			onChange={(selected) => {
				const selectedAttributes = selected as AttributeReference[];
				console.log('onChange', selectedAttributes);
				if (selectedAttributes.length > 0) {
					setSelected(selectedAttributes);
					//field.onChange(selectedAttributes.map((selectedAttribute) => selectedAttribute.id!));
				}
				else {
					setSelected([]);
					//field.onChange([]);
					// setValue(`choices.${index}.dependantOnAttributeChoiceId`, undefined);
				}
			}}
		/>
		{valueOptions && <span className="mx-2"> value is </span>}
		{valueOptions && <Typeahead
			//id={`choices.${props.index}.dependantOnAttributeChoiceIds`}
			options={valueOptions}
			labelKey="label"
			allowNew={false}
			clearButton={true}
			emptyLabel={'Select...'}
			multiple={true}
			isLoading={false}
			onChange={(selected) => {
				const val = selected as AttributeChoice[];
				if (val.length > 0) {
					setSelectedValues(val);
				}
				else {
					setSelectedValues([]);
				}
			}}
		/>}
		{selectedValues.length > 0 && <button type="button" className="ms-2 btn btn-outline-primary" onClick={addCondition}>Done</button>}
	</div>;
}

type AttributeConditionEditorProps = {
	index: number;
	field: FieldArrayWithId<Attribute, "showIf", "id">;
	entity: EntityTypes;
	onRemove: (field: FieldArrayWithId<Attribute, "showIf", "id">) => void;
	//condition: AttributeCondition;
}

function AttributeConditionEditor(props: AttributeConditionEditorProps) {
	const dispatch = useAppDispatch();
	const config = useAppSelector(selectConfig).attributes;

	const [attribute, setAttribute] = useState<Attribute>();
	const [attributeChoices, setAttributeChoices] = useState<AttributeChoice[]>();

	useEffect(() => {
		const entityType = (props.field.relatedEntity ?? props.entity);
		dispatch(fetchAttributes(entityType));
		if (config[entityType]) {
			setAttribute(config[entityType]?.find((attrib) => attrib.id === props.field.attributeId));
		}
	},[config, dispatch, props.entity, props.field.attributeId, props.field.relatedEntity]);

	useEffect(() => {
		if (attribute) {
			setAttributeChoices(attribute.choices?.filter((choice) => props.field.value?.choicesValue?.includes(choice.id!) ) );
		}
		else {
			setAttributeChoices(undefined);
		}
	}, [attribute, props.field.value]);

	return <div key={`condition-${props.field.id}`} className="d-flex flex-row me-1">
		{props.index > 0 && <div className="me-1">OR</div>}
		<button type="button" onClick={(e) => props.onRemove(props.field)} className="btn btn-xs btn-success">{attribute?.entity && attribute?.entity !== props.entity && <><EntityTypeLabel entityType={attribute!.entity} />: </>} {attribute?.name}</button>
		{attributeChoices && <div className="mx-2">is</div>}
		{attributeChoices && <div>{attributeChoices.map((choice) => <span className="badge bg-warning me-1">{choice.label}</span>)}</div>}
	</div>
}